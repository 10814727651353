import { FilterItem } from "../components/filter/filterItem";
import http from "../lib/http";
import { rootUrl } from "./baseUrl";
// const url = "http://localhost:8080/truck/";
const url = rootUrl("FLEET") + "jobrengermove/"

const getAllTruck = () => {
    console.log(url + "truck/allTruck");
    return http.get(url + "truck/allTruck");
};

const getAllTruckType = () => {
    console.log(url + "truck/allTruckType");
    return http.get(url + "truck/allTruckType");
};

const getAllPartner = () => {
    console.log(url + "truck/allPartner");
    return http.get(url + "truck/allPartner");
};


const getTruckByRegistId = (registId: string) => {
    console.log("getTruckByregistId" + registId)
    return http.get(url + "truck/" + registId);
};

const addTruck = (Truck: any) => {
    console.log("addTruck >>> ", Truck)
    return http.post(url + "truck/addTruck", Truck);
};


const getAllDriver = () => {
    console.log(url + "driver/allDriver");
    return http.get(url + "driver/allDriver");
};

const getDriverByName = (data: string) => {
    console.log("getDriverByname" + data)
    return http.get(url + "driver/" + data);
};

const addDriver = (Driver: any) => {
    console.log("addDriver >>> ", Driver)
    return http.post(url + "driver/addDriver", Driver);
};


const addJobRengerMove = (Jobrengermove: any) => {
    console.log("addJobRengerMove >>> ", Jobrengermove)
    return http.post(url + "addJobRengerMove", Jobrengermove);
};

const updateJobRengerMove = (Jobrengermove: any) => {
    console.log("updateJobRengerMove >>> ", Jobrengermove)
    return http.post(url + "updateJobRengerMove", Jobrengermove);
};

// const searchJobRengerMove = (Jobrengermove: any) => {
//     console.log("searchJobRengerMove >>> ", Jobrengermove)
//     return http.post(url + "searchJob", Jobrengermove);
// };

const search = (filterList : FilterItem[]) => {
    console.log("filterList >>> ", filterList)
    return http.post(url + "search", filterList);
};

const getJobRengerMoveByJobNo = (jobNo: string) => {
    console.log("getJobRengerMoveByJobNo" + jobNo)
    return http.get(url + "jobRengerMoveByJobNo/" + jobNo);
};


export default {
    getAllTruck,
    getTruckByRegistId,
    addTruck,
    getAllTruckType,
    getAllPartner,
    getAllDriver,
    getDriverByName,
    addDriver,
    addJobRengerMove,
    updateJobRengerMove,
    // searchJobRengerMove,
    search,
    getJobRengerMoveByJobNo
}