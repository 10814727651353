import { DeleteOutlined } from '@ant-design/icons';
import React, { FC, PropsWithChildren } from 'react'

interface FilterBodyProps {
  filterList: any[],
  onDeleteItem: (name: string) => void;
}

const FilterBody: FC<PropsWithChildren<FilterBodyProps>> =  (props) => {

  const { filterList, onDeleteItem } = props;

  const handleDeleteItem = (name: string) => {
    console.log(name);
    onDeleteItem(name);
  }

  return (
    filterList.length > 0
    ? (<div style={{ padding: '5px 10px 5px 10px' }}>
      <ul>
        {
          filterList.map(item => (item.isHidden === undefined || !item.isHidden) && (
            <li key={item.name}>{item.message} - <span style={{ cursor: 'pointer'}} onClick={() => handleDeleteItem(item.name)} ><DeleteOutlined  style={{ fontSize: '14px', fontWeight: '500', color: 'red'}} /></span> </li>
          ))
        }
      </ul>
    </div>
    )
    : <div></div>
  )
}

export default FilterBody