import { useState } from "react";
import { FilterItem } from "../filterItem";


const useFilter = () => {

  const [filterList, setFilterList] = useState<FilterItem[]>([]);

  const isFilter = (name: string) => {
    console.log("filter : " + name);
    return filterList.find(item => item.name === name) !== undefined;
  }

  const addOrUpdateItem = (name: string, filterItem: FilterItem) => {
    const _filterList = [...filterList];

    const item = _filterList.filter(item => item.name === name);

    if (item.length === 0) {
      setFilterList([...filterList, filterItem]);
    } else {

      _filterList.forEach(item => {
        if (item.name === name) {
          item.message = filterItem.message;
          item.payload = filterItem.payload;
        }
      })

      // console.log("_filterList b", _filterList);
      setFilterList([..._filterList]);
      }
  }

  const deleteItem = (name: string) => {
    const _filterList = [...filterList];

    const item = _filterList.filter(item => item.name === name);
    if (item.length === 0) return;


    const _item = _filterList.filter(item => item.name !== name);
    setFilterList([..._item]);

  }

  return {
    filterList,
    addOrUpdateItem,
    deleteItem,
    isFilter
  };
}

export default useFilter