const dateRangeOverlaps = (a_start: Date, a_end: Date, b_start: Date, b_end: Date) => {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
    if (b_start <  a_start && a_end   <  b_end) return true; // a in b
    return false;
}
export default {
    convert2ShowDateFormat: (oldDate: string) => {
        let year = oldDate.substring(0, 4);
        let month = oldDate.substring(4, 6);
        let date = oldDate.substring(6, 8);
        let yearInt = parseInt(year);
        yearInt = yearInt < 2500 ? yearInt + 543 : yearInt;
        return date + "/" + month + "/" + yearInt;
    },
    convert2ShowTimeFormat: (oldTime: string) => {
        let hour = oldTime.substring(0, 2);
        let min = oldTime.substring(2, 4);
        let sec = oldTime.substring(4, 6);
        return hour + ":" + min + ":" + sec;
    },
    convert2ShowTimeTHFormat: (oldTime: string) => {
        let hour = oldTime.substring(0, 2);
        let min = oldTime.substring(2, 4);
        return hour + "." + min + " น.";
    },
    convert2ShowMonthDateFormat: (oldDate: string) => {
        let year = oldDate.substring(0, 4);
        let month = oldDate.substring(4, 6);
        let date = oldDate.substring(6, 8);
        // let yearInt = parseInt(year);
        // yearInt = yearInt < 2500 ? yearInt + 543 : yearInt;
        return month + "/" + date;
    },
    convert2ShowDateMonthFormat: (oldDate: string) => {
        let year = oldDate.substring(0, 4);
        let month = oldDate.substring(4, 6);
        let date = oldDate.substring(6, 8);
        // let yearInt = parseInt(year);
        // yearInt = yearInt < 2500 ? yearInt + 543 : yearInt;
        return date + "/" + month;
    },
    convert2YearMonthFormat: (oldDate: string) => {
        let year = oldDate.substring(0, 4);
        let month = oldDate.substring(4, 6);
        let date = oldDate.substring(6, 8);
        // let yearInt = parseInt(year);
        // yearInt = yearInt < 2500 ? yearInt + 543 : yearInt;
        return year + "_" + month;
    },
    convert2DBDateFormat: (oldDate: string) => { //DD/MM/YYYY
        if(oldDate == undefined || oldDate == null){
            return null;
        }
        const format = (value: string) => {
            return value.length > 1 ? value : "0" + value;
        }
        let splitDate = oldDate.split("/");
        return splitDate[2] + format(splitDate[1]) + format(splitDate[0]);
    },
    convertDate2DBDateFormat: (date: Date) => {
        // if(date == undefined || date == null){
        //     return null;
        // }
        const format = (value: string) => {
            return value.length > 1 ? value : "0" + value;
        }
        let monthVal = format((date.getMonth() + 1).toString());
        let dateVal = format(date.getDate().toString());
        let yearVal = date.getFullYear();
        
        return yearVal + monthVal + dateVal;
    },
    convertDate2DBTimeFormat: (date: Date) => {
        // if(date == undefined || date == null){
        //     return null;
        // }
        const format = (value: string) => {
            return value.length > 1 ? value : "0" + value;
        }
        let sec = format(date.getSeconds().toString());
        let min = format(date.getMinutes().toString());
        let hour = format(date.getHours().toString());
        
        return hour + min + sec;
    },
    convertDBDateStr2Date: (oldDate: string) => {
        // if(oldDate == null || oldDate.trim() == ""){
        //     return null;
        // }
        let year = parseInt(oldDate.substring(0, 4));
        let month = parseInt(oldDate.substring(4, 6));
        let date = parseInt(oldDate.substring(6, 8));
        let newDate = new Date(year, (month - 1), date);

        return newDate;
    },
    getDiffDate: (fromDate: Date, toDate: Date) => {
        // console.log("diffTime: ", (toDate.getTime() - fromDate.getTime()));
        let diffVal = (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24);

        // if(diffVal < 0){
        //     console.log((toDate.getTime() + "-" + fromDate.getTime()) + " = ",  ((toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24)));
        // }
        console.log("diffVal: ", diffVal);
        return Number.isNaN(diffVal) ? 365 : Math.floor(diffVal);
    },
    hasOverlapDate: (dateList: {fromDate: Date, toDate: Date}[], currentDate: {fromDate: Date, toDate: Date}) => {
        if(dateList == null || currentDate == null){
            return false;
        }
        for(let i = 0; i < dateList.length; i++){
            let date = dateList[i];
            if(dateRangeOverlaps(date.fromDate, date.toDate, currentDate.fromDate, currentDate.toDate)){
                return true;
            }
        }
        return false;
    },
    getLastDayOfMonth: (dateStr: string) => {
        let year = parseInt(dateStr.substring(0, 4));
        let month = parseInt(dateStr.substring(4, 6));
        let date = parseInt(dateStr.substring(6, 8));
        return new Date(year, month, 0);
    },
    getFirstDayOfMonth: (dateStr: string) => {
        let year = parseInt(dateStr.substring(0, 4));
        let month = parseInt(dateStr.substring(4, 6));
        let date = parseInt(dateStr.substring(6, 8));
        return new Date(year, month - 1, 1);
    },
}