import { ColumnsType } from "antd/lib/table";
import BookingHead from 'src/model/truckmatching/BookingHead';
import BookingRoute from "src/model/truckmatching/BookingRoute";
import dateUtils from "src/utils/dateUtils";

export const SharingColumn: ColumnsType<BookingHead> = [
    { 
      title: "วันที่จอง",
      dataIndex: "bookDate",
      key: "bookDate",
      width: 90,
      sorter: (a: BookingHead, b: BookingHead) => {return a.bookDate.localeCompare(b.bookDate)},
      sortDirections: ["descend", 'ascend', ],
      render: (value: string, item: BookingHead, index: number) => (
          dateUtils.convert2ShowDateFormat(value)
      ),
    },
    { 
      title: "เลขที่การจอง",
      dataIndex: "bookNo",
      key: "bookNo",
      width: 90,
      sorter: (a: BookingHead, b: BookingHead) => {return a.bookNo.localeCompare(b.bookNo)},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: BookingHead, index: number) => (
          value
      ),
    },
    { 
      title: "ชื่อลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      width: 90,
      sorter: (a: BookingHead, b: BookingHead) => {return a.customerName && b.customerName ? a.customerName.localeCompare(b.customerName) : -1},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: BookingHead, index: number) => (
          value
      ),
    },
    { 
      title: "ชนิดรถ",
      dataIndex: "truckTypeName",
      key: "truckTypeName",
      width: 90,
      render: (value: string, item: BookingHead, index: number) => (
          value
      ),
    },
    { 
      title: "วันที่ใช้งาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 90,
      sorter: (a: BookingHead, b: BookingHead) => {return a.startDate.localeCompare(b.startDate)},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: BookingHead, index: number) => (
        dateUtils.convert2ShowDateFormat(value)
      ),
    },
    { 
      title: "เวลาใช้งาน",
      dataIndex: "startTime",
      key: "startTime",
      width: 90,
      render: (value: string, item: BookingHead, index: number) => (
        dateUtils.convert2ShowTimeTHFormat(value)
      ),
    },
    { 
      title: "ต้นทาง",
      dataIndex: "originLocationName",
      key: "originLocationName",
      width: 90,
      sorter: (a: BookingHead, b: BookingHead) => {return a.originLocationName && b.originLocationName ? a.originLocationName.localeCompare(b.originLocationName) : -1},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: BookingHead, index: number) => (
          value
      ),
    },
    { 
      title: "ปลายทาง",
      dataIndex: "destinationLocationName",
      key: "destinationLocationName",
      width: 90,
      sorter: (a: BookingHead, b: BookingHead) => {return a.destinationLocationName && b.destinationLocationName ? a.destinationLocationName.localeCompare(b.destinationLocationName) : -1},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: BookingHead, index: number) => (
        value
      ),
    },
];