import React, { useState, useEffect } from "react";

type justifyType = "center" | "start" | "end" | "between" | "around";
type alignItemsType = "center" | "start" | "end";
type flexDirectionType = "row" | "column";

interface IProps {
  className?: string;
  alignItems?: alignItemsType;
  justifyContent?: justifyType;
  children?: React.ReactNode;
  headerClassName?: string;
  items: Array<any>;
  renderBody: Function;
  showItem?: number;
  onContentScroll?: Function;
  contentScrollRef?: Function;
  contentScrollLink?: HTMLElement;
  onItemClicked?: Function;
}

const Carousel: React.FC<IProps> = ({
  className,
  alignItems = "center",
  justifyContent = "start",
  children,
  items,
  renderBody,
  showItem = 3,
  onContentScroll,
  contentScrollRef,
  contentScrollLink,
  onItemClicked,
}) => {
    const [mainContainer, setMainContainer] = useState<HTMLDivElement | undefined>();
    const [upButton, setUpButton] = useState<HTMLDivElement | undefined>();
    const [downButton, setDownButton] = useState<HTMLDivElement | undefined>();
    const [childBody, setChildBody] = useState<HTMLDivElement | undefined>();
    const [contentBody, setContentBody] = useState<HTMLOListElement | undefined>();
    const [topItemIndex, setTopItemIndex] = useState<number>(0);
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
    const [currScroll, setCurrScroll] = useState<number>(0);

    useEffect(() => {
        // Update the document title using the browser API
        // let childBounding = childBody?.getBoundingClientRect();
        console.log("contentBodyEffect");
        let contentBodyChildCount = contentBody ? contentBody.childElementCount : 0;
        let maxHeight = 0;
        for(let i = 0; i < contentBodyChildCount && i < showItem; i++){
            let currChildBounding = contentBody?.children.item(i)?.getBoundingClientRect();
            maxHeight += currChildBounding ? currChildBounding.height : 0;
        }
        if(childBody){
            childBody.style.height = maxHeight + "px";
        }
        if(contentBody){
            contentBody.style.height = maxHeight + "px";
        }
        if(contentScrollRef){
            contentScrollRef(contentBody);
        }
    }, [contentBody]);

    const onUpClick = () => {
        console.log("upClick");
        if(contentBody){
            let currChildBounding = contentBody.children.item(topItemIndex)?.getBoundingClientRect();
            console.log("currChildBounding: ", currChildBounding);
            console.log("contentBodyBounding: ", contentBody.getBoundingClientRect());
            let nextTop = contentBody?.getBoundingClientRect().top - (currChildBounding? currChildBounding.height : 0);
            console.log('nextTop: ' + nextTop);
            contentBody.scrollTop += currChildBounding? currChildBounding.height : 0;
            console.log('contentBody: ' + contentBody);
        }
    }
    const onDownClick = () => {
        console.log("downClick");
        if(contentBody){
            let currChildBounding = contentBody.children.item(topItemIndex)?.getBoundingClientRect();
            console.log("currChildBounding: ", currChildBounding);
            console.log("contentBodyBounding: ", contentBody.getBoundingClientRect());
            let nextTop = contentBody?.getBoundingClientRect().top - (currChildBounding? currChildBounding.height : 0);
            console.log('nextTop: ' + nextTop);
            contentBody.scrollTop -= currChildBounding? currChildBounding.height : 0;
            console.log('contentBody: ' + contentBody);
        }
    }
    const [handleScroll, setHandleScroll] = useState<any>(null);
    const onContentScrollFn = () => {
        // if(onContentScroll){
            if(handleScroll != null){
                clearTimeout(handleScroll);
                setHandleScroll(null);
            }
            setHandleScroll(setTimeout(() => {
                if(contentBody){
                    if(onContentScroll){
                        onContentScroll(contentBody.scrollTop);
                    }else{
                        onMyContentScroll(contentBody.scrollTop);
                    }
                }
            }, 50))
        // }
    }
    const onMyContentScroll = (scrollTop: number) => {
        console.log("onMyContentScroll: ", scrollTop);
        if(contentScrollLink){
            console.log("contentScrollLink.scrollTop: ", contentScrollLink.scrollTop);
            contentScrollLink.scrollTop = scrollTop;
        }
    }
    // const onOlMouseDown = (evt: React.MouseEvent<HTMLOListElement>) => {
    //     // console.log("onOlMouseDown: ", evt);
    //     setIsMouseDown(true);
    //     if(contentBody){
    //         setCurrScroll(contentBody.scrollTop);
    //     }
    // }

    // const onOlMouseUp = (evt: React.MouseEvent<HTMLOListElement>) => {
    //     // console.log("onOlMouseUp: ", evt);
    //     setIsMouseDown(false);
    // }

    // const [sumScore, setSumScore] = useState<number>(0);
    // const [tmpSumScore, setTmpSumScore] = useState<number>(0);
    // useEffect(() => {
    //     // console.log("scrollTopB: ", contentBody.scrollTop);
    //     setCurrScroll(currScroll - sumScore);
    //     console.log("scrollingY: ", sumScore);
    //     // console.log("scrollTopA: ", contentBody.scrollTop);
    //     setSumScore(0);
    // }, [sumScore]);
    // useEffect(() => {
    //     if(contentBody){
    //         contentBody.scrollTop = currScroll;
    //     }
    // }, [currScroll]);
    // const onOlMouseMove = (evt: React.MouseEvent<HTMLOListElement>) => {
    //     if(isMouseDown){
    //         // setSumScore(sumScore + evt.movementY);
    //         if(evt.movementY != 0){
    //             setTmpSumScore(tmpSumScore + evt.movementY);
    //         }else{
    //             setSumScore(tmpSumScore);
    //             setTmpSumScore(0);
    //         }
    //     }
    // }
    return (
        <div
            ref={(el: HTMLDivElement) => {setMainContainer(el);}}
            className={`d-flex ${className ? className : ""} flex-column 
                ${alignItems ? "align-items-" + alignItems : ""} ${
                justifyContent ? "justify-content-" + justifyContent : ""
            }`}
            // onMouseUp={(evt: React.MouseEvent<HTMLDivElement>) => {setIsMouseDown(false)}}
            style={{ width: "100%", paddingTop: '10px' }}
        >
            <div className={`d-flex ${className ? className : ""}`}
                ref={(el: HTMLDivElement) => {setUpButton(el);}}
                onClick={onUpClick}
                style={{...styles.arrow, ...styles.up}}>
                {/* <span>up</span> */}
            </div>
            {/* {children} */}
            <div className="d-flex flex-column "
                ref={(el: HTMLDivElement) => {setChildBody(el);}}
                style={{ width: "100%", flexFlow: 'wrap', overflow: 'hidden' }}>
                <ol 
                    ref={(el: HTMLOListElement) => {setContentBody(el);}}
                    onScroll={onContentScrollFn}
                    // onMouseDown={(event: React.MouseEvent<HTMLOListElement>) => {onOlMouseDown(event)}}
                    // onMouseUp={(event: React.MouseEvent<HTMLOListElement>) => {onOlMouseUp(event)}}
                    // onMouseMove={(event: React.MouseEvent<HTMLOListElement>) => {onOlMouseMove(event)}}
                    style={{ width: "100%", overflow: 'hidden', scrollBehavior: 'smooth', scrollSnapType: 'y mandatory', overflowY: 'auto', listStyle: 'none', padding: '0 0 0 0', margin: '0' }}>
                    {items.map((item,index)=>{
                        return (
                            <li 
                                style={{ cursor: onItemClicked == null ? 'default' : 'pointer'}}
                                onClick={() => {
                                    if(onItemClicked){
                                        onItemClicked(item, index);
                                    }
                                }}>
                                {renderBody(item, index)}
                            </li>
                        );
                    })}
                </ol>
            </div>
            <div className={`d-flex ${className ? className : ""}`}
                ref={(el: HTMLDivElement) => {setDownButton(el);}}
                onClick={onDownClick}
                style={{...styles.arrow, ...styles.down}}>
                {/* <span>down</span> */}
            </div>
        </div>
    );
};

const styles = {
    button: {
        width: '60px', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: '#F8F8F8', 
        padding: '5px 0px 5px 0px', 
        margin: '5px 0px 5px 0px', 
        borderRadius: 5, 
        cursor: "pointer", 
    },
    arrow: {
        border: 'solid #CCC',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: '3px',
        cursor: "pointer", 
        margin: '10px 0 10px 0'
    },
    
    right: {
        transform: 'rotate(-45deg)',
        // -webkit-transform: rotate(-45deg),
    },
    
    left: {
        transform: 'rotate(135deg)',
        // -webkit-transform: rotate(135deg),
    },
    
    up: {
        transform: 'rotate(-135deg)',
        // -webkit-transform: rotate(-135deg),
    },
    
    down: {
        transform: 'rotate(45deg)',
        // -webkit-transform: rotate(45deg),
    },
}
export default Carousel;
