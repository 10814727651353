import { AutoComplete, Button, Col, Divider, Form, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { Truck } from 'src/domain/truckDataType';
import { FilterItem } from '../../components/filter/filterItem';
import classes from "../mastercss/master.module.css";
import JobRengerMoveService from "src/services/JobRengerMoveService";

const { Option } = AutoComplete;

interface IProps {
  onOK:(filterItem:FilterItem)=>void
}

const SearchTruckRegistPopup: React.FC<IProps> = (props) => {

  const [form] = Form.useForm();
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [truckOptions, setTruckOptions] = useState<Truck[]>([]);
  const [selectTruck, setSelectTruck] = useState<string>("");
  const [truckValue, setTruckValue] = useState<String>("");
  const [trucks, setTrucks] = useState<Truck[]>([]);

  useEffect(() => {

    JobRengerMoveService.getAllTruck().then((res) => {
      setTrucks(res.data);
      setTruckOptions(res.data);
    })
    console.log("trucks ", trucks);

  }, []);

  const handleOnSelectTruck = (value: any, option: any) => {
    //  const data =  trucks.filter( (item)=>{ return item.truckUId === option.key})
    setSelectTruck(option.key);
    setTruckValue(option.value);
    console.log("select ", value);
    console.log("option ", option);
  }

  const handleChangeTruck = (value: any) => {
    console.log("onChange ", value)
    setSelectTruck("");
    setTruckValue(value);
  }

  const handleSearch = (value: any) => {
    console.log("handleSearch ", value)
    const data = trucks.filter(item => item.registId.includes(value));
    setTruckOptions(data);
  }

  const handleOnClick =()=>{

    const filterItem : FilterItem ={
      name:"truckRegist",
      message:'ทะเบียนรถ :'+ truckValue,
      payload:{
        truckRegist: truckValue,
      }

    }
    props.onOK(filterItem);
 }

 const onReset = () => {
  form.resetFields();
};

  return (
    <>
     <Form
         // {...formItemLayout}
         // layout={formLayout}
         form={form}
         onFinish={handleOnClick}
         // initialValues={{ layout: formLayout }}
         // onValuesChange={onFormLayoutChange}
      >
     <Form.Item
        // label="Username"
        name="truckUId"
        rules={[{ required: true, message: 'กรุณาเลือกทะเบียนรถ' }]}
      >
        <AutoComplete
              // style={{ width: '200px' }}
              onSelect={handleOnSelectTruck}
              onChange={handleChangeTruck}
              value={truckValue}
              onSearch={handleSearch}
              placeholder="ทะเบียนรถ"
            >
              {truckOptions.map((item) =>
                <Option
                  value={item.registId}
                  key={item.truckUId}
                // label={item.registId}
                >

                  {item.registId}

                </Option>
              )}

            </AutoComplete>
      </Form.Item>

      </Form>
      <Space direction="vertical" >
        {/* <Row>
          <Col>

            <AutoComplete
              style={{ width: '200px' }}
              onSelect={handleOnSelectTruck}
              onChange={handleChangeTruck}
              value={truckValue}
              onSearch={handleSearch}
            >
              {truckOptions.map((item) =>
                <Option
                  value={item.registId}
                  key={item.truckUId}
                // label={item.registId}
                >

                  {item.registId}

                </Option>
              )}

            </AutoComplete>
          </Col>
        </Row> */}
        <Row>
          <Col>
          <Space>
            <Button onClick={onReset}>ล้าง</Button>
            <Button type="primary" danger onClick={()=>{form.submit()}}>ok</Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </>
  )
}

export default SearchTruckRegistPopup