import { AutoComplete, Col, Form, Row, Input, Select, Space, TimePicker, InputNumber, Button, Divider, Modal, message, Typography, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { Driver } from 'src/domain/driverType';
import { Truck } from 'src/domain/truckDataType';
import classes from "../mastercss/jobrenger.module.css";
import jobRengerMoveService from "src/services/JobRengerMoveService";
import AddTruckPopup from './AddTruckPopup';
import AddDriverPopup from './AddDriverPopup';
import { Partner } from 'src/domain/partnerType';

import { JobRengerMove } from 'src/domain/jobRengerMoveType';
import { showMessage } from 'src/utils';

import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { RouteComponentProps, useHistory } from 'react-router';
import FormOfficer from '../officer/FormOfficer';
import { isNullAllEmpty } from 'src/utils/stringUtil';
import moment from 'moment';

interface IProps {
  id: string;
  jobType: string;
  jobNo ?:string;
}

const { TextArea } = Input;
const { Option } = AutoComplete;
// const { RangePicker } = DatePicker;

const AddJobRengerMove: React.FC<RouteComponentProps<IProps>> = (props) => {

  const id = 0;
  const jobId = 0;
  const userId = 1;
  const username = "sittiponn.a"
  const station = "11";

  const jobNo = props.match.params.jobNo;

  // console.log("props  >>>",jobNo)

  const history = useHistory();
  const [form] = Form.useForm();
  const [data, setData] = useState<string>();

  const [jobRengerMove , setJobRengerMove] = useState<JobRengerMove>();

  const [partners, setPartners] = useState<Partner[]>([]);

  const [isModalVisible, setIsModalVisible] = useState<boolean>();
  const [trucks, setTrucks] = useState<Truck[]>([]);
  const [selectTruck, setSelectTruck] = useState<{truckUId: string, registId: string} | null>(null);
  const [truckValue, setTruckValue] = useState<string>("");
  const [truckOptions, setTruckOptions] = useState<Truck[]>([]);
  // const [newTruck, setNewTruck] = useState<Truck>({} as Truck);

  const [driverFsts, setDriverFsts] = useState<Driver[]>([]);
  const [isModalDriverFstVisible, setIsModalDriverFstVisible] = useState<boolean>();
  const [selectDriverFst, setSelectDriverFst] = useState<{drvFstUId:string, drvFstName: string } | null>(null);
  const [driverFstValue, setDriverFstValue] = useState<string>("");
  const [driverFstOptions, setDriverFstOptions] = useState<Driver[]>([]);

  const [driverSecs, setDriverSecs] = useState<Driver[]>([]);
  const [isModalDriverSecVisible, setIsModalDriverSecVisible] = useState<boolean>();
  const [selectDriverSec, setSelectDriverSec] = useState<{drvSecUId:string, drvSecName:string} | null>(null);
  const [driverSecValue, setDriverSecValue] = useState<string>("");
  const [driverSecOptions, setDriverSecOptions] = useState<Driver[]>([]);

  const [jobStartDate, setJobStartDate] = useState<string>("");
  const [jobStartTime, setJobStartTime] = useState<string>("");
  const [jobEndDate, setJobEndDate] = useState<string>("");
  const [jobEndTime, setJobEndTime] = useState<string>("");

  const [jobType, setJobType] = useState("");
  const [loading, setLoading] = useState(false);

  const [distanceData, setDistanceData] = useState<number>(0);
  const [salePriceAmtData, setSalePriceAmtData] = useState<number>(0);
  const [partnerUId, setPartnerUId] = useState<string>("");

  const [isAddTruck,setIsAddtruck] = useState(false);

  useEffect(() => {

    jobRengerMoveService.getAllTruck().then((res) => {
      setTrucks(res.data);
      setTruckOptions(res.data);
    })

    jobRengerMoveService.getAllDriver().then((res) => {
      setDriverFsts(res.data);
      setDriverSecs(res.data);

      setDriverFstOptions(res.data);
      setDriverSecOptions(res.data);

      console.log("setDriverFsts ", res.data);

    })

    jobRengerMoveService.getAllPartner().then((res) => {
      setPartners(res.data);
      console.log("setPartners ", res.data);
    })

    console.log("jobType   >>>  ", props.match.params.jobType)
    setJobType(props.match.params.jobType);

    if(jobNo !== undefined){
      jobRengerMoveService.getJobRengerMoveByJobNo(jobNo).then((res) => {
       setJobRengerMove(res.data);
       console.log("setJobRengerMove ", res.data);
     })
    }

    // setTruckValue("ชม.70-9020");
    // form.setFieldValue("truckRegistId", "ชม.70-9020");
  }, []);


  useEffect(() => {
    console.log("jobRengerMove >>>>",  jobRengerMove )

    if (jobRengerMove !== undefined) {

      // console.log(dateTime1);

      setDistanceData(Number(jobRengerMove.distance))
      setSalePriceAmtData(Number(jobRengerMove.salePriceAmt))

        form.setFieldsValue({
            id: jobRengerMove.id,
            bookNo: jobRengerMove.bookNo,
            jobNo: jobRengerMove.jobNo,
            partnerJobRefNo: jobRengerMove.partnerJobRefNo,
            partnerUId: jobRengerMove.partnerUId,
            partnerName: jobRengerMove.partnerName,
            jobStartDate: moment(jobRengerMove.jobStartDate +" "+jobRengerMove.jobStartTime) ,
            jobEndDate: moment(jobRengerMove.jobEndDate +" "+jobRengerMove.jobEndTime) ,
            // jobTime: jobRengerMove.partnerUId ,
            // jobStartDate: jobRengerMove.jobStartDate,
            // jobStartTime: jobRengerMove.jobStartTime,

            // jobEndDate: jobRengerMove.jobEndDate,
            // jobEndTime: jobRengerMove.jobEndTime,
            // status: values.partnerUId ,
            truckUId: jobRengerMove.truckUId,
            truckRegistId: jobRengerMove.truckRegistId,
            driverFirstUId: jobRengerMove.driverFirstUId,
            driverFirstName: jobRengerMove.driverFirstName,
            driverSecondUId: jobRengerMove.driverSecondUId,
            driverSecondName:jobRengerMove.driverSecondName,
            jobType: jobRengerMove.jobType,
            jobDesc: jobRengerMove.jobDesc,
            remarkCancel: "",
            systemRemark: "",
            distance:  distanceData,//values.distance Number(jobRengerMove.distance)
            costAmt: 0,
            salePriceAmt: setSalePriceAmtData,
            // salePriceAmt: values.salePriceAmt ,

            // userIdLogin: userId,
            // usernameLogin: username,
            // savStation: station,

        });
        console.log("fomr ",form);


    }
}, [jobRengerMove]);



  const handleOkTruck = (value: any) => {
    console.log("handleOktruck ", value)
    // setNewTruck(value);
     setTrucks([...trucks, value]);
     setTruckOptions([...truckOptions, value]);
     setTruckValue(value.registId);
     setIsModalVisible(false);
     setSelectTruck({truckUId: value.truckUId , registId: value.registId});
    //  handleSearch(value.registId);
     form.setFieldValue("truckRegistId", value.registId);
    // setTruckValue("999999");
  };



  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOnSelectTruck = (value: any, option: any) => {

    setSelectTruck({truckUId:option.key, registId:option.value});
    setTruckValue(option.value)
    // setTruckValue("ชม.70-9020")
    // console.log("ชม.70-9020 xxxxx", );
    // console.log("selecttruck ", value);
    // console.log("option ", option);
  }

  const handleChangeTruck = (value: any) => {
    console.log("onChangetruck ", value)
    // setSelectTruck("");
    setTruckValue(value);
    form.setFieldValue("truckRegistId", value);
  }

  const handleSearch = (value: any) => {
    console.log("handleSearch",value)
    console.log("handleSearch",trucks)
    const data = trucks.filter(item => item.registId.includes(value));
    setTruckOptions(data);
    console.log("searchTruck ",data)
  }


  /////

  const handleDriverFstOk = (value: any) => {
    console.log("handleOk ", value)
    setDriverFsts([...driverFsts, value]);
    setDriverFstOptions([...driverFsts, value]);
    setDriverFstValue(value.fullname);
    setIsModalDriverFstVisible(false);
    // handleSearchDriverFst(value.fullname);
    setSelectDriverFst(value.driverUId);
    form.setFieldValue("driverFirstName", value.fullname);
  };

  const handleDriverFstCancel = () => {
    setIsModalDriverFstVisible(false);
  };

  const handleOnSelectDriverFst = (value: any, option: any) => {
    //  const data =  trucks.filter( (item)=>{ return item.truckUId === option.key})
    setSelectDriverFst({drvFstUId :option.key ,drvFstName : option.value});
    setDriverFstValue(option.value);
    console.log("select ", value);
    console.log("option ", option);
  }

  const handleChangeDriverFst = (value: any) => {
    console.log("onChange ", value)
    // setSelectDriverFst("");
    setDriverFstValue(value);
  }

  const handleSearchDriverFst = (value: any) => {
    const data = driverFsts.filter(item => item.fullname!.includes(value));
    setDriverFstOptions(data);
  }


  /////

  const handleDriverSecOk = (value: any) => {
    console.log("handleOk ", value)
    setDriverSecs([...driverSecs, value]);
    setDriverSecOptions([...driverSecs, value]);
    setDriverSecValue(value.fullname);
    setIsModalDriverSecVisible(false);
    // handleSearchDriverSec(value.fullname);
    setSelectDriverSec(value.driverUId);
    form.setFieldValue("driverSecondName", value.fullname);
  };

  const handleDriverSecCancel = () => {
    setIsModalDriverSecVisible(false);
  };

  const handleOnSelectDriverSec = (value: any, option: any) => {
    //  const data =  trucks.filter( (item)=>{ return item.truckUId === option.key})
    setSelectDriverSec(option.key);
    setSelectDriverSec({drvSecUId :option.key ,drvSecName : option.value});
    setDriverSecValue(option.value);
    console.log("select ", value);
    console.log("option ", option);
  }

  const handleChangeDriverSec = (value: any) => {
    console.log("onChange ", value)
    // setSelectDriverSec("");
    setDriverSecValue(value);
  }

  const handleSearchDriverSec = (value: any) => {
    const data = driverSecs.filter(item => item.fullname ? item.fullname.includes(value) :false);
    setDriverSecOptions(data);
  }



  const onChangeJobStartDate = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {

    // const datetimeValue = value as moment.Moment;
    // setJobStartDate(datetimeValue.format("YYYYMMDD"));
    // setJobStartTime(datetimeValue.format("hhmmss"));
  };

  const onOkJobStartDate = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    console.log('onOk: ', (value as moment.Moment).format("YYYYMMDD"));

    const datetimeValue = value as moment.Moment;

    setJobStartDate(datetimeValue.format("YYYYMMDD"));
    setJobStartTime(datetimeValue.format("hhmmss"));

  };


  const onChangeJobEndDate = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    // console.log('Selected Time: ', value);
    // console.log('Formatted Selected Time: ', dateString);
    // const datetimeValue = value as moment.Moment;

    // setJobEndDate(datetimeValue.format("YYYYMMDD"));
    // setJobEndTime(datetimeValue.format("hhmmss"));
  };

  const onOkJobEndDate = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    console.log('onOk: ', (value as moment.Moment).format("YYYYMMDD"));

    const datetimeValue = value as moment.Moment;
    setJobEndDate(datetimeValue.format("YYYYMMDD"));
    setJobEndTime(datetimeValue.format("hhmmss"));

  };

  ////

  const validateJob = (job : JobRengerMove): boolean =>{
     console.log(form.getFieldValue("partnerJobRefNo"))
     form.getFieldValue("partnerName")
    if(!isNullAllEmpty( form.getFieldValue("partnerJobRefNo"))){
       if(isNullAllEmpty( form.getFieldValue("partnerName")) ){
          message.error("มีเลขที่อ้างอิงต้องเลือกบริษัท") ;
          return false
       }
    }

    console.log("select",selectTruck) ;
    console.log("truckvalue",truckValue) ;
     if(selectTruck?.registId.trim() !== truckValue.trim()){
      message.error("ทะเบียนรถไม่ถูกต้องกรุณาเลือกทะเบียนรถใหม่") ;
      return false
     }

     console.log("selectDriverFst ",selectDriverFst) ;
     console.log("driverFstValue",driverFstValue) ;
      if(selectDriverFst?.drvFstName.trim() !== driverFstValue.trim()){
       message.error("พนักงานขับรถหลักไม่ถูกต้องกรุณาเลือกพนักงานขับรถหลักอีกครั้ง") ;
       return false
      }

      if(!isNullAllEmpty( driverSecValue.trim())){
      if(selectDriverFst?.drvFstName.trim() === selectDriverSec?.drvSecName.trim()){
        message.error("พนักงานขับรถหลักและสำรองไม่ควรชื่อเดี่ยวกันกรุณาเลือกพนักงานขับรถสำรองอีกครั้ง") ;
        return false
      }

      if(selectDriverSec?.drvSecName.trim() !== driverSecValue.trim()){
        message.error("พนักงานขับรถสำรองไม่ถูกต้องกรุณาเลือกพนักงานขับรถสำรองอีกครั้ง") ;
        return false
       }
      }

    return true;
  }


  const onFinish = async (values: any) => {

    console.log(distanceData);


    const _job: JobRengerMove = {

      id: jobId,
      // bookNo: values.bookNo,
      jobNo: values.jobNo,
      partnerJobRefNo: values.partnerJobRefNo,

      // partnerJobRefNo: values.partnerJobRefNo ,
      partnerUId: partnerUId,
      partnerName: values.partnerName,
      // jobDate: values.partnerUId ,
      // jobTime: values.partnerUId ,
      jobStartDate: jobStartDate,
      jobStartTime: jobStartTime,

      jobEndDate: jobEndDate,
      jobEndTime: jobEndTime,
      // status: values.partnerUId ,
      truckUId: selectTruck?.truckUId,

      truckRegistId: selectTruck?.registId,
      driverFirstUId: selectDriverFst?.drvFstUId,
      driverFirstName: selectDriverFst?.drvFstName,
      driverSecondUId: selectDriverSec?.drvSecUId,
      driverSecondName: selectDriverSec?.drvSecName,
      jobType: jobType,

      jobDesc: values.jobDesc,
      remarkCancel: "",
      systemRemark: "",
      distance: distanceData,//values.distance
      costAmt: 0,
      salePriceAmt: salePriceAmtData,
      // salePriceAmt: values.salePriceAmt ,


      userIdLogin: userId,
      usernameLogin: username,
      savStation: station,

    };

    console.log(_job);

    if (!validateJob(_job)) {
      setLoading(false);
      return;
    }

    if (id === 0) {
        try {
          const res = await jobRengerMoveService.addJobRengerMove(_job)
          setTimeout(() => {
            console.log("onfinist ",res.data);
            if(res.data.status === true){
              message.success("save success", 1.5);
              const job:JobRengerMove = {..._job,jobNo:res.data.jobNo}
              // handleSave(job);
              history.replace("/app/jobrenger/list")
            }else{
              message.error("บันทึกไม่สำเร็จ ", 1.5);
              setLoading(false);
            }
          }, 1000);

        } catch (err) {
            showMessage("Something went wrong!");
            setLoading(false);
            // setTrigger(false);
        }
    }

    // else if (id !== 0) {
    //     try {
    //         const res = await truckService.updateTruck(_truck, partnerUId, truckId);
    //         history.replace("/app/truck/view/" + id);
    //         message.success("Update truck Complete!", 1.5);
    //     } catch (err) {
    //         showMessage("Something went wrong!");
    //     }
    // }
  };

  const onFinishFailed = (errorInfo: any) => {
    setLoading(false);
  };

  const handleSave = (value: any) => {
    console.log("save value", value)

  }

  const onChangeDistance = (value: number | string | null ) => {
    // console.log('changed', value);
     console.log("type of value ", value);
    if (typeof value === "number") {
      setDistanceData(value);
    }

  };

  const onChangeSalePriceAmtData = (value: number | string | null) => {
    // console.log('changed', value);
    if (typeof value === "number") {
      setSalePriceAmtData(value);
    }
  };

  const handleSelectPartner = (value: string) => {
     console.log(`selected ${value}`);
    // console.log(value);
    setPartnerUId(value)
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 7 }}
      // wrapperCol={{ span: 10 }}
      >
        <Row >
          <Col span={2} ></Col>
          <Col span={12}>
            <Form.Item
              label="เลขที่ใบงาน"
              name="jobNo"
            // rules={[{ required: true, message: 'กรอกชื่อพนักงาน' ,  },{max : 255,message:'ความยาวไม่เกิน 255 อักษร'}]}
            // required tooltip="This is a required field"
            >
              {/* <Input placeholder="" /> */}
              <Typography>{jobNo}</Typography>
            </Form.Item>
          </Col>
          {/* <Col span={2}></Col> */}
          <Col span={10} >
            <Form.Item
              label="เลขที่อ้างอิง"
              name="partnerJobRefNo"
            // rules={[{ required: true, message: 'กรอกชื่อพนักงาน' ,  },{max : 255,message:'ความยาวไม่เกิน 255 อักษร'}]}
            // required tooltip="This is a required field"
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="วันเวลาที่เริ่มต้น"
              name="jobStartDate"
              rules={[{ required: true, message: 'เลือกวันที่เริ่มต้น', }]}
            // required tooltip="This is a required field"
            >
              {/* <Input.Group> */}
              <DatePicker showTime
                onChange={onChangeJobStartDate}
                onOk={onOkJobStartDate}
                format="YYYY-MM-DD HH:mm" />
              {/* <TimePicker /> */}
              {/* </Input.Group> */}
            </Form.Item>
          </Col>
          {/* <Col span={5}>
            <Form.Item name="time">
              <TimePicker />
            </Form.Item>
          </Col> */}

          {/* <Col span={2}></Col> */}
          <Col span={10}>
            <Form.Item
              label="บริษัท"
              name="partnerName"
            // rules={[{ required: true, message: 'กรอกชื่อพนักงาน' ,  },{max : 255,message:'ความยาวไม่เกิน 255 อักษร'}]}
            // required tooltip="This is a required field"
            >

              {/* <Select defaultValue="" style={{ width: 120 }}  onChange={handleChangePartner}>
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
                <Option value="Yiminghe">yiminghe</Option>
              </Select> */}

              <Select
                showSearch
                style={{ width: 200 }}
                onChange={handleSelectPartner}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA!.children as unknown as string)
                    .toLowerCase()
                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                }>

                {partners.map((item) =>
                  <Option
                    value={item.partnerUId}
                    key={item.partnerUId}
                  // label={item.registId}
                  >

                    {item.name}

                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>


        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="วันเวลาที่สิ้นสุด"
              name="jobEndDate"
              rules={[{ required: true, message: 'เลือกวันที่สิ้นสุด', }]}
            // required tooltip="This is a required field"
            >

              {/* <Input.Group> */}
              <DatePicker
                showTime
                onChange={onChangeJobEndDate}
                onOk={onOkJobEndDate}
                format="YYYY-MM-DD HH:mm" />
              {/* <TimePicker />
              </Input.Group> */}
            </Form.Item>

          </Col>
        </Row>

        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="ทะเบียนรถ"
              name="truckRegistId"
              rules={[{ required: true, message: 'เลือกทะเบียนรถ', }]}
              required tooltip="This is a required field"
            >

              <AutoComplete
                className={classes.inputtext}
                 onSelect={handleOnSelectTruck}
                // value={truckValue}
                onChange={handleChangeTruck}
                onSearch={handleSearch}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ padding: '0px 10px' }}>
                      <Button type="primary" size='middle' block
                        onClick={() => setIsModalVisible(true)}
                      >เพิ่มข้อมูล</Button>
                    </div>
                  </>
                )}
              >
                {truckOptions.map((item) =>
                  <Option
                    value={item.registId}
                    key={item.truckUId}
                  >
                    {item.registId}
                  </Option>
                )}

              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>

        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="พนักงานขับรถหลัก"
              name="driverFirstName"
              rules={[{ required: true, message: 'กรอกชื่อพนักงาน', }, { max: 255, message: 'ความยาวไม่เกิน 255 อักษร' }]}
              required tooltip="This is a required field">

              <AutoComplete
                className={classes.inputtext}
                onSelect={handleOnSelectDriverFst}
                onChange={handleChangeDriverFst}
                value={driverFstValue}
                onSearch={handleSearchDriverFst}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ padding: '0px 10px' }}>
                      <Button type="primary" size='middle' block
                        onClick={() => setIsModalDriverFstVisible(true)}
                      >เพิ่มข้อมูล</Button>
                    </div>
                  </>
                )}
              >
                {driverFstOptions.map((item) =>

                  <Option
                    value={item.fullname}
                    key={item.driverUId}
                  //  label={item.fullName}
                  >
                    {/* {console.log(item.fullName)} */}
                    {item.fullname}

                  </Option>
                )}

              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>

        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="พนักงานขับรถสำรอง"
              name="driverSecondName"
            // rules={[{ required: true, message: 'กรอกชื่อพนักงาน' ,  },{max : 255,message:'ความยาวไม่เกิน 255 อักษร'}]}
            // required tooltip="This is a required field"
            >


              <AutoComplete
                className={classes.inputtext}
                onSelect={handleOnSelectDriverSec}
                onChange={handleChangeDriverSec}
                value={driverSecValue}
                onSearch={handleSearchDriverSec}
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div style={{ padding: '0px 10px' }}>
                      <Button type="primary" size='middle' block
                        onClick={() => setIsModalDriverSecVisible(true)}
                      >เพิ่มข้อมูล</Button>
                    </div>
                  </>
                )}
              >
                {driverSecOptions.map((item) =>

                  <Option
                    value={item.fullname}
                    key={item.driverUId}
                  //  label={item.fullName}
                  >
                    {/* {console.log(item.fullName)} */}
                    {item.fullname}

                  </Option>
                )}

              </AutoComplete>

            </Form.Item>
          </Col>
        </Row>

        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="รายละเอียดงาน"
              name="jobDesc"
              rules={[{ max: 255, message: 'ความยาวไม่เกิน 255 อักษร' }]}
            // required tooltip="This is a required field"
            >

              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="ระยะทาง"
              name="distance"
            // rules={[{ required: true, message: 'กรอกชื่อพนักงาน', }, { max: 255, message: 'ความยาวไม่เกิน 255 อักษร' }]}
            // required tooltip="This is a required field"
            >
              <InputNumber value={distanceData} onChange={onChangeDistance} className={classes.inputnum} /> ก.ม
            </Form.Item>
          </Col>
        </Row>

        <Row >
          <Col span={2} ></Col>
          <Col span={12} >
            <Form.Item
              label="ราคาเที่ยววิ่ง"
              name="salePriceAmt"
            // rules={[{ required: true, message: 'กรอกราคาเที่ยววิ่ง', }]}
            // required tooltip="This is a required field"
            >

              <InputNumber value={salePriceAmtData } onChange={onChangeSalePriceAmtData} className={classes.inputnum} /> บาท
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row >
        <Col span={24} style={{ textAlign: "center" }}>
          <Space direction="horizontal" >
            <div>
              ${truckValue}
            </div>
            <Button loading={loading} onClick={() => { setLoading(true); form.submit() }}>บันทึก</Button>
            <Button >ยกเลิก</Button>
          </Space>
        </Col>
      </Row>


      <Modal title="เพิ่มข้อมูลรถ"
        visible={isModalVisible}
        footer={null}
        // onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose={true}
      // width={1000}
      >
        <div>
          <AddTruckPopup
            onOK={handleOkTruck}
          ></AddTruckPopup>
        </div>
      </Modal>

      <Modal title="เพิ่มข้อมูลพนักงาน"
        visible={isModalDriverFstVisible}
        footer={null}
        //  onOk={handleDriverOk}
        onCancel={handleDriverFstCancel}
        maskClosable={false}
        destroyOnClose={true}
      // width={1000}
      >
        <div>
          <AddDriverPopup
            onOK={handleDriverFstOk}
            // driverType={"fst"}
          ></AddDriverPopup>

        </div>
      </Modal>

      <Modal title="เพิ่มข้อมูลพนักงานสำรอง"
        visible={isModalDriverSecVisible}
        footer={null}
        //  onOk={handleDriverOk}
        onCancel={handleDriverSecCancel}
        maskClosable={false}
        destroyOnClose={true}
      // width={1000}
      >
        <div>
          <AddDriverPopup
            onOK={handleDriverSecOk}
            // driverType={"sec"}
          ></AddDriverPopup>

        </div>
      </Modal>
    </>
  )
}

export default AddJobRengerMove