import React, { useEffect, useState } from 'react';
import { Button, Layout, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Post } from 'src/domain/postType';
import postService from 'src/services/postService';
import { Subject } from 'src/domain/subjectType';
import GroupContainer from 'src/components/container/GroupContainer';
import subjectService from 'src/services/subjectService';
// import './MatchTruck.css'
import Carousel from 'src/components/container/Carousel';
import HorizontalCalendar, { IHorizonTalCalendarData, IActivateDateData } from 'src/components/container/HorizontalCalendar';
import styles from './TruckMatchingList.module.css';
// import DateFormat from 'src/utils/dateUtils';
import { keyframes } from 'styled-components';
import { Content } from 'antd/lib/layout/layout';
import BookingHead from 'src/model/truckmatching/BookingHead';
import Truck from 'src/model/truckmatching/Truck';
import truckMatchService from 'src/services/truckMatchService';
import { render } from '@testing-library/react';
import BookingRoute from 'src/model/truckmatching/BookingRoute';
import DateUtil from 'src/utils/dateUtils'

interface IParams {
    // selectedBooking: BookingHead,
    // selectedTruckList: Truck[]
}

const TruckMatchingSendRequest: React.FC<RouteComponentProps<IParams>> = (props) => {
    const [selectedBooking, setSelectedBooking] = useState<BookingHead>();
    const [selectedTruckList, setSelectedTruckList] = useState<Truck[]>([]);
    const [truckGroupByPartner, setTruckGroupByPartner] = useState<{[name: string]: {partnerName: string, truckList: Truck[]}}>({});
    const [checkPointList, setCheckPointList] = useState<BookingRoute[]>([]);

    const history = useHistory();
    useEffect(() => {
          let paramObjs:any = props.location.state;
          loadRoute(paramObjs.selectedBooking);
          setSelectedTruckList(paramObjs.selectedTruckList);
    },[]);

    useEffect(() => {
        groupTruck(selectedTruckList);
    }, [selectedTruckList]);

    useEffect(() => {
        if(selectedBooking){
            initCheckPointList();
        }
    }, [selectedBooking]);


    const initCheckPointList = () => {
        // let datas = [
        //     {name: "ศูนย์รับสินค้านิ่มช้อป สนญ ชม."},
        //     {name: "ศูนย์พักสินค้า กทม."},
        //     {name: "ศูนย์รับสินค้านิ่มช้อป พิษณุโลก"}
        // ];
        if(selectedBooking && selectedBooking.bookingRoutes){
            setCheckPointList(selectedBooking.bookingRoutes);
        }else{
            setCheckPointList([]);
        }
    }

    const loadRoute = async (booking: BookingHead) => {
        await truckMatchService.getBookingHead(booking.bookNo).then((res) => {
            if(res && res.data){
                console.log("loadRoute: ", res.data);
                let bookingData:BookingHead = res.data;
                setSelectedBooking(bookingData);
            }
        });
    }
    
    const onCommitClicked = async () => {
        if(selectedBooking){
            for(let i = 0; i < selectedTruckList.length; i++){
                selectedTruckList[i].truckId = selectedTruckList[i].id;
            }
            selectedBooking.truckList = selectedTruckList;
            selectedBooking.bookId = selectedBooking.id;
            
            await truckMatchService.addTruckRequest(selectedBooking).then((res) => {
                if(res){
                    if(res.data){
                        console.log("addTruckBooking success: ", res.data);
                        history.replace("/app/matching/list");
                    }else{
                        console.log("addTruckBooking fail: ", res.data);
                        alert("ส่งข้อมูลไม่สำเร็จ");
                    }
                }
            });
        }
    }

    const groupTruck = (truckList: Truck[]) => {
        let dictionary: {[name: string]: {partnerName: string, truckList: Truck[]}} = {};
        for(let i = 0; i < truckList.length; i++){
            let truck: Truck = truckList[i];
            let key = "" + truck.partnerId;
            if(!dictionary[key]){
                dictionary[key] = {partnerName: truck.partnerName, truckList: []};
            }
            dictionary[key].truckList.push(truck);
            setTruckGroupByPartner(dictionary);
        }
    }

    const renderPartnerGroup = () => {
        let resultStack: JSX.Element[] = [];
        let partnerKeys: string[] = Object.keys(truckGroupByPartner);
        partnerKeys.map((key: string, index: number) => {
            let truckGroupList = truckGroupByPartner[key];
            resultStack.push(
                (
                    <div style={{ margin: "5px 15px 5px 15px", paddingBottom: "5px"}}>
                        <div className={`${styles["columnBody"]}`}>
                            { truckGroupList.partnerName }
                            { renderTruck(truckGroupList.truckList) }
                        </div>
                    </div>
                )
            );
        });
        return resultStack;
    }

    const renderTruck = (truckGroupList: Truck[]) => {
        let truckGroupStack: JSX.Element[] = [];
        truckGroupList.map((data: Truck, index: number) => {
            let style = index == 0 ? {marginLeft: '20px', width: '80%'} : {marginLeft: '20px', width: '80%' , borderTop: "1px solid #C4C4C4", marginTop: "5px", paddingTop: "6px"}
            truckGroupStack.push(
                (
                    <div style={style}>
                        { data.registId }<br/>
                        <span style={{color: '#C4C4C4'}}>{data.driverName}</span>
                    </div>
                )
            );
        });
        return truckGroupStack;
    }

    const renderCheckPoint = () => {
        let checkPointStack: JSX.Element[] = [];
        checkPointList.forEach((checkPoint: BookingRoute, index: number) => {
            checkPointStack.push(
                (
                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>จุดที่ {checkPoint.seq}: </div>
                        <div style={{flex: 3}}>{checkPoint.locationName}</div>
                    </div>
                )
            );
        });
        return checkPointStack;
    }

    return (
        <div>
            <PageHeader title="จับคู่รถ">
                <Button
                    size="small"
                    type="default"
                    // icon={<PlusOutlined />}
                    onClick={() => { history.goBack() }} 
                    >ยกเลิก
                </Button>
                &nbsp;
                <Button
                    size="small"
                    type="primary"
                    // icon={<PlusOutlined />}
                    onClick={onCommitClicked} 
                    >ส่งข้อมูล
                </Button>


            </PageHeader>
            <Content className="app-page-content">
                <div className={styles["contentBody"]} style={{minHeight: '300px'}}>
                    <div className={styles["rowBody"]}>
                        <div style={{flex: 1, margin: "5px 5px 5px 5px",}} className={`${styles["rowBody"]}`}></div>
                        <div style={{flex: 6, margin: "5px 5px 5px 5px", backgroundColor: "white",}} className={`${styles["rowBody"]}`}>
                            <div style={{flex: 5, margin: "10px 15px 10px 15px", backgroundColor: "#fafafa"}} className={`${styles["rowBody"]} ${styles["radiusBody"]}`}>
                                <div style={{flex: 2, margin: "20px 15px 10px 5px", backgroundColor: "#fafafa"}} className={`${styles["columnBody"]}`}>
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>เลขที่การจอง: </div>
                                        <div style={{flex: 3}}>{selectedBooking?.bookNo}</div>
                                    </div>
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>วัน - เวลาที่เริ่มใช้รถ: </div>
                                        <div style={{flex: 3}}>{selectedBooking != null ? DateUtil.convert2ShowDateFormat(selectedBooking.startDate) : ""} : {selectedBooking?.startTime}</div>
                                    </div>
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>วัน - เวลาที่สิ้นสุดใช้รถ: </div>
                                        <div style={{flex: 3}}>{selectedBooking != null ? DateUtil.convert2ShowDateFormat(selectedBooking.endDate) : ""} : {selectedBooking?.endTime}</div>
                                    </div>
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>ชื่อลูกค้า: </div>
                                        <div style={{flex: 3}}>{selectedBooking != null ? selectedBooking.customerName : ""}</div>
                                    </div>
                                    { renderCheckPoint() }
                                    {/* {renderCheckPoint()}
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>จุดที่ 1: </div>
                                        <div style={{flex: 3}}>ศูนย์รับสินค้านิ่มช้อป สนญ ชม.</div>
                                    </div>
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>จุดที่ 2: </div>
                                        <div style={{flex: 3}}>ศูนย์พักสินค้า กทม.</div>
                                    </div>
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>จุดที่ 3: </div>
                                        <div style={{flex: 3}}>ศูนย์รับสินค้านิ่มช้อป พิษณุโลก</div>
                                    </div> */}
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["rowBody"]}`}>
                                        <div style={{flex: 2, textAlign: 'right', marginRight: '5px'}}>ชนิดรถ: </div>
                                        <div style={{flex: 3}}>{selectedBooking?.truckTypeName}</div>
                                    </div>
                                </div>
                                <div style={{flex: 1, margin: "20px 5px 10px 15px", backgroundColor: "white"}} className={`${styles["columnBody"]} ${styles["radiusBody"]}`}>
                                    <div style={{margin: "10px 10px 10px 10px"}} className={`${styles["columnBody"]}`}>
                                        { renderPartnerGroup() }
                                        {/* <div style={{ margin: "5px 15px 5px 15px", paddingBottom: "5px"}}>
                                            <div className={`${styles["columnBody"]}`}>
                                                บริษัท นิ่มทรัค จำกัด
                                                <div style={{marginLeft: '20px', width: '80%'}}>
                                                    ชม.70-2004<br/>
                                                    <span style={{color: '#C4C4C4'}}>นาย คณนาถ เจริญไชย</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: "5px 15px 5px 15px", paddingBottom: "5px"}}>
                                            <div className={`${styles["columnBody"]}`}>
                                                บริษัท พิทักษ์ดั้ม จำกัด
                                                <div style={{marginLeft: '20px', width: '80%'}}>
                                                    ชม.70-2005<br/>
                                                    <span style={{color: '#C4C4C4'}}>นาย กษิดิศ พิมพ์สุวรรณ</span>
                                                </div>
                                                <div style={{marginLeft: '20px', width: '80%' , borderTop: "1px solid #C4C4C4", marginTop: "5px", paddingTop: "6px"}}>
                                                    ชม.70-2006<br/>
                                                    <span style={{color: '#C4C4C4'}}>นางสาว วีรญา ทาอินทร์</span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{flex: 1, margin: "5px 5px 5px 5px",}} className={`${styles["rowBody"]}`}></div>
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default TruckMatchingSendRequest;
