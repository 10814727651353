import React from "react";
import classes from "./SideNavMenu.module.css";
import { Menu } from "antd";
import { Link } from "react-router-dom";
// import logoImg from "../assets/images/logo-title.png";
import logoImg from "../assets/images/app-logo.jpg";
import { useAppSelector } from "src/store";

interface IProps {
  menuItems: { id: number; name: string; path: string, roles: string[] }[];
  onMenuClick: () => void;
  showMenu?: boolean;
}

const menus = (
  <Menu
    // onClick={this.handleClick}
    style={{ width: "225px" }}
    defaultSelectedKeys={["1"]}
    defaultOpenKeys={["sub1"]}
    mode="inline"
    className="sideNavMenuContainer"
  >
    <Menu.Item key="1">Option 1</Menu.Item>
    <Menu.Item key="2">Option 2</Menu.Item>
  </Menu>
);

const SideNavMenu: React.FC<IProps> = (props) => {
  const { sessionUser } = useAppSelector((state) => state.auth);

  const _menuItems = props.menuItems;

  console.log("_menuItems", _menuItems);

  // const _menuItems = props.menuItems.filter(item => {
  //   let isFind = false;
  //   sessionUser.roles.forEach(role => {
  //     if (!isFind) isFind = item.roles.includes(role)
  //   })

  //   return isFind;
  // })

  const menuList = () => {
    return (
      <Menu
        // onClick={this.handleClick}
        // style={{ width: "225px", transition: 'width 2s' }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        className="sideNavMenuContainer"
      >
        {_menuItems && _menuItems.map((menu) => (
          <Menu.Item key={menu.id}>
            <Link to={menu.path} onClick={props.onMenuClick}>{menu.name}</Link>
          </Menu.Item>
        ))}
      </Menu>
    );
  };



  return (
		<div className={classes.sideNavMenu}>
      <>
			<div className={classes.sideNavMenuBrandName}>
				<div style={{ margin: 10, padding: 5, width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 8, backgroundColor:'#FDC307' }}>
					<img src={logoImg} style={{ width: "120px", height: "auto" }} />
				</div>
			</div>
			{menuList()}
      </>
		</div>
	);
};

export default SideNavMenu;
