import http from "../lib/http";
import { rootUrl } from "./baseUrl";
// const url = "http://localhost:8080/custbusiness/";
const url = rootUrl("FLEET")+"custbusiness/"


const getAllPrename = () => {  
    return http.get(url+"prenmeaAll");
};


const getPreanameById = (id: number) => {
  console.log("getTruckById" + id)
  return http.get(url+"prenmeaById/"+id);
};
  
  const addCustomer = (Customer: any) => {
    console.log("addCustomer >>> ", Customer)
    return http.post(url+"addCustomer", Customer);
  };

  const updateCustomer = (Customer: any) => {
    console.log("updateCustomer >>> ", Customer)
    return http.post(url+"updateCustomer", Customer);
  };

  const addUser = (SecUser: any) => {
    console.log("addUser >>> ", SecUser)
    return http.post(url+"addUser", SecUser);
  };

  const updateUser = (SecUser: any) => {
    console.log("updateUser >>> ", SecUser)
    return http.post(url+"updateSecUser", SecUser);
  };


  const getSecUserByCustomerId = (customerid: number) =>  {
    console.log("getSecUserByCustomerId >>> ", customerid)
      return http.get(url+"userbycustomerid/"+customerid); 
  }

  const getLocationByData = (searcdata: string) => {
    console.log("location" + searcdata)
    return http.get(url+"location/"+searcdata);
  };

  const getLocationAll = () => {  
    console.log("locationall");
    return http.get(url+"locationall");
  };

  const getLocationByAddressPath = (searcdata: string) => {  
    // console.log("ssssssssssssssssssssss " + searcdata);
    return http.get(url+"location/addresspath/"+searcdata);
  };

  const getCustomerBusiinessByCodition = (searcdata: string) => {  
    if(searcdata === null || searcdata === ''){
      searcdata = 'null'
    }
    return http.get(url+"byCodition/"+searcdata);
  };

  const getBusinessJobList = (custUId: string) => {  
    if(custUId === null || custUId === ''){
      custUId = 'null'
    }
    return http.get(url+"businessJobList/"+custUId);
  };

 

  const getCustomerById = (id: number) => {  
    return http.get(url+"byid/"+id);
  };

  const getBookingHeadListByCustId = (custId: number) => {  
    return http.get(url+"businessBookHistory/"+custId);
  };


export default {
    getAllPrename,
    getPreanameById,
    addCustomer,
    updateCustomer,
    addUser,
    updateUser,
    getLocationByData,
    getLocationAll,
    getLocationByAddressPath,
    getCustomerBusiinessByCodition,
    getBusinessJobList,
    getCustomerById,
    getSecUserByCustomerId,
    getBookingHeadListByCustId,
};