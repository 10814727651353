
import { Button, Modal, Menu, Table, Pagination, Tag, Row, Col, Dropdown, Space, Form, Radio, RadioChangeEvent } from 'antd';
import Search from 'antd/lib/input/Search';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined, FilterOutlined, CloseOutlined } from "@ant-design/icons";
// import classes from "../mastercss/jobrenger.module.css";
import classes from "./JobRengerMoveList.module.css";
import { JobRengerMove } from 'src/domain/jobRengerMoveType';
import { RouteComponentProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import customerBusinessService from "src/services/customerBusinessService";
import { useAppSelector } from "../../store";
import { SessionUser } from 'src/store/authen/types';
import { DownOutlined, UpOutlined, DeleteOutlined } from '@ant-design/icons';
import SearchDatePopup from './SearchDatePopup';
import SearchJoborderPopup from './SearchJoborderPopup';
import JobRengerMoveService from 'src/services/JobRengerMoveService';
import SearchJobStatusPopup from './SearchJobStatusPopup';
import SearchTruckRegistPopup from './SearchTruckRegistPopup';
import DFlex from '../../components/container/DFlex';
import useFilter from '../../components/filter/hooks/useFilter';
import { FilterItem } from '../../components/filter/filterItem';
import FilterContainer from '../../components/filter/components/FilterContainer';
import FilterHeader from '../../components/filter/components/FilterHeader';
import FilterSubject from '../../components/filter/components/FilterSubject';


interface IParams {
  id: string;
  userId: string;
  username: string;
  station: string;
  // userUId: string;
  // partnerUId ?: any;
  // sessionUser : SessionUser;
}




const JobRengerMoveList: React.FC<RouteComponentProps<IParams>> = (props) => {

  const { sessionUser } = useAppSelector((state) => state.auth)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const history = useHistory();
  const [jobRengerMoveDataList, setJobRengerMoveDataList] = useState<JobRengerMove[]>([]);
  const [searchData, setSearchData] = useState<any>("");
  const [page, setPage] = useState(1);

  const [dateVisble, setDateVisible] = useState(false);
  const [jobVisble, setJobVisible] = useState(false);
  const [jobStatusVisble, setJobStatusVisble] = useState(false);

  const [truckRegistVisble, setTruckRegistVisble] = useState(false);
  const [jobType, setJobType] = useState<"LONG-HAUL" | "DISTRIBUTE">("LONG-HAUL");

  // const [filterList, setFilterList] = useState<FilterItem[]>([]);
  const filter = useFilter();



  useEffect(() => {

    // customerBusinessService.getCustomerBusiinessByCodition("")
    //     .then((res) => {
    //         console.log(res.data);
    //         setBusinessList(res.data)
    //     })
    // console.log("businessList >>> " + businessList,)

    const filterItem: FilterItem = {
      name: "jobType",
      payload: {
        jobType: jobType
      },
      message: "",
      isHidden: true
    }
    handleSearchJobType(filterItem);

  }, []);

  useEffect(() => {
    if (filter.filterList.length > 0) actionFilter();

  }, [filter.filterList]);


  const onSearch = (value: any) => {
    // setSearchData(value);
    // customerBusinessService.getCustomerBusiinessByCodition(value)
    //     .then((res) => {
    //         setBusinessList(res.data)
    //     })
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const columns = [

    {

      title: 'ลำดับ',
      dataIndex: 'seq',
       render: (value: string, item: JobRengerMove, index: number) => ((page - 1) * 10 + index)+1 ,
    },
    {
      title: 'วันที่จอง',
      dataIndex: 'jobDateStr',
      // render: (value: string, item: JobRengerMove, index: number) => <Link to={`/app/business/edit/${item.id}`}>{value}</Link>,
    },
    {
      title: 'วันที่ใช้งาน',
      dataIndex: 'jobStartDateStr',
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'jobEndDateStr',
    },
    {
      title: 'เลขที่ใบงาน',
      dataIndex: 'jobNo',
      render: (value: string, item: JobRengerMove, index: number) => <Link to={`/app/jobrenger/view/${item.jobNo}`}>{value}</Link>
    },

    {
      title: 'ทะเบียนรถ',
      dataIndex: 'truckRegistId',
    },
    {
      title: 'ชื่อ พขร หลัก',
      dataIndex: 'driverFirstName',
    },
    {
      title: 'ชื่อ พขร สำรอง',
      dataIndex: 'driverSecondName',
    },
    {
      title: 'ระยะทาง',
      dataIndex: 'distance',
    },
    {
      title: 'ราคาเที่ยววิ่ง',
      dataIndex: 'salePriceAmt',
    },
    {
      title: 'เลขที่อ้างอิง',
      dataIndex: 'partnerJobRefNo',
    },
    {
      title: 'บริษัท',
      dataIndex: 'partnerName',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'jobDesc',
    },
    {
      title: 'สถานะ',
      dataIndex: 'statusStr',
    },
  ];

  const actionFilter = () => {
    JobRengerMoveService.search(filter.filterList).then((res) => {
      console.log(res.data);
      setJobRengerMoveDataList(res.data);
    })

    console.log(jobRengerMoveDataList);
  }

  const handleSearchDate = (filterItem: FilterItem) => {
    setDateVisible(false);
    filter.addOrUpdateItem("date", filterItem);
  }


  const handleSearchJoborder = (filterItem: FilterItem) => {
    setJobVisible(false);
    filter.addOrUpdateItem("joborder", filterItem);
  }

  const handleDeleteItem = (name: string) => {
    filter.deleteItem(name);
  }

  const dateSearch =  (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchDatePopup onOK={handleSearchDate}/>
    </div>
  )

  const handleSearchStatus = (filterItem: FilterItem) => {

    setJobStatusVisble(false);
    console.log("status filterItem ", filterItem)

    filter.addOrUpdateItem("status", filterItem);

  }

  const handleSearchTruckRegist = (filterItem: FilterItem) => {
    setTruckRegistVisble(false);
    filter.addOrUpdateItem("truckRegist", filterItem);
  }

  const handleSearchJobType = (filterItem: FilterItem) => {
    setJobVisible(false);
    filter.addOrUpdateItem("jobType", filterItem);
  }

  const statusSearch = (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchJobStatusPopup
        onOK={handleSearchStatus} ></SearchJobStatusPopup>
    </div>
  );

  const joborderSearch = (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchJoborderPopup
        onOK={handleSearchJoborder} ></SearchJoborderPopup>
    </div>
  );

  const truckRegistSearch = (
    <div style={{ backgroundColor: '#DDD', padding: '5px', width: '250px' }}>
      <SearchTruckRegistPopup
        onOK={handleSearchTruckRegist} ></SearchTruckRegistPopup>
    </div>
  );

  const FilterTitle = (props:any) => {
    return <span className={classes.filterTitle}>{props.children}</span>
  }

  const optionsRadio = [
    { label: 'วิ่งระยะไกล', value: 'LONG-HAUL' },
    { label: 'วิ่งกระจาย', value: 'DISTRIBUTE' },
  ];

  const onChangeJobType = (event: RadioChangeEvent) => {
    console.log('onChangeJobType', event.target.value);
    setJobType(event.target.value);

    const filterItem: FilterItem = {
      name: "jobType",
      payload: {
        jobType: event.target.value,
      },
      message: "",
      isHidden: true
    }

    handleSearchJobType(filterItem);
  };

  const handleOnActive = (subjectName: string, isActive: boolean) => {
    setDateVisible('date' === subjectName && isActive);
    setJobVisible('jobType' === subjectName && isActive);
    setJobStatusVisble('status' === subjectName && isActive);
    setTruckRegistVisble('truckRegist' === subjectName && isActive);
  }

  return (
    <>
      <PageHeader
        title="โปรแกรมออกใบสั่งปฎิบัติงาน"
        subTitle="แสดงรายการ ค้นหา เพิ่ม เปลี่ยนแปลง">
        <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginTop: "1em", marginLeft: "1em" }}
            onClick={() => {
                console.log("jobType", jobType)
                history.push("/app/jobrenger/form/"+jobType)
              }
            }
          >
            New
          </Button>
      </PageHeader>

      <Content className="app-page-content" >
        <FilterContainer filterList={filter.filterList} onDeleteItem={handleDeleteItem}>
          <FilterHeader>
            <div>
              <Radio.Group
                options={optionsRadio}
                onChange={onChangeJobType}
                value={jobType}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div  style={{}}><FilterTitle>ตัวกรอง</FilterTitle></div>

            <div  style={{}}>
              <div >
                <FilterSubject
                  label="วันที่"
                  subjectName='date'
                  overlay={dateSearch}
                  visible={dateVisble}
                  className={classes.overlaySearch}
                  onActive={handleOnActive}
                  checkFiltered={filter.isFilter}
                />
                {/* <Dropdown overlay={dateSearch} visible={dateVisble} className={classes.overlaySearch} trigger={['click']}>
                  <span style={{ cursor: 'pointer' }} onClick={e => {
                     e.preventDefault();
                     setDateVisible(prev => !prev);
                     setJobVisible(false);
                     setJobStatusVisble(false);
                     setTruckRegistVisble(false); }}>
                    <Space >
                      <FilterTitle>วันที่</FilterTitle>
                      {dateVisble ? <UpOutlined /> : <DownOutlined />}
                    </Space>
                  </span>
                </Dropdown> */}
              </div>
            </div>
            <div style={{}}>
            {/* overlayStyle={{ padding: '10px', backgroundColor: '#DDD' }} */}
            <FilterSubject
                  label="ทะเบียนรถ"
                  subjectName='truckRegist'
                  overlay={truckRegistSearch}
                  visible={truckRegistVisble}
                  className={classes.overlaySearch}
                  onActive={handleOnActive}
                  checkFiltered={filter.isFilter}
                />
              {/* <Dropdown overlay={truckRegistSearch} visible={truckRegistVisble} className={classes.overlaySearch} trigger={['click']}>
                <span style={{ cursor: 'pointer' }} onClick={e => {
                  e.preventDefault();
                  setTruckRegistVisble(prev => !prev)
                  setJobVisible(false);
                  setJobStatusVisble(false);
                  setDateVisible(false);}}>
                  <Space>
                    <FilterTitle>ทะเบียนรถ</FilterTitle>
                    {jobVisble ? <UpOutlined /> : <DownOutlined />}
                  </Space>
                </span>
              </Dropdown> */}
            </div>
            <div style={{}}>
              <div >
                <Dropdown overlay={joborderSearch} visible={jobVisble} className={classes.overlaySearch} trigger={['click']}>
                  <span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault();
                    setJobVisible(prev => !prev);
                    setTruckRegistVisble(false);
                    setJobStatusVisble(false);
                    setDateVisible(false); }}>
                    <Space>
                      <FilterTitle>เลขที่ใบงาน</FilterTitle>
                      {jobVisble ? <UpOutlined /> : <DownOutlined />}
                    </Space>
                  </span>
                </Dropdown>

              </div>

            </div>
            <div style={{}}>
              <div >
                <Dropdown overlay={statusSearch} visible={jobStatusVisble} className={classes.overlaySearch} trigger={['click']}>
                  <span style={{ cursor: 'pointer' }} onClick={e => { e.preventDefault();
                    setJobStatusVisble(prev => !prev);
                    setTruckRegistVisble(false);
                    setJobVisible(false);
                    setDateVisible(false); }}>
                    <Space>
                      <FilterTitle>สถานะ</FilterTitle>
                      {jobStatusVisble ? <UpOutlined /> : <DownOutlined />}
                    </Space>
                  </span>
                </Dropdown>

              </div>
            </div>

          </FilterHeader>
        </FilterContainer>
        {/* <div style={{ textAlign: 'left' }}>

          <Button
            size="small"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
                console.log("jobType", jobType)
                history.push("/app/jobrenger/form/"+jobType)
              }
            }

          >
            เพิ่ม
          </Button>
        </div> */}
        <div>
          <Table style={{
            marginTop: "1em",
          }}

            rowKey={(item: JobRengerMove) => item.id}
            dataSource={jobRengerMoveDataList}
            columns={columns}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
          // pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '15']}}
          />
        </div>
      </Content>

    </>
  )
}

export default JobRengerMoveList