import { Button, Col, Input, Row, Tabs, Form, Radio, List, Image, Switch, Typography, Divider, Space, AutoComplete, Select, message } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import Avatar from 'antd/lib/avatar/avatar';
import classes from "../owner/master.module.css";
import { Anchor } from 'antd';
import imageDriver from "../../assets/images/driver.jpg"
import Section from 'src/components/container/Section';
import { Location } from 'src/domain/locationType';
import { Driver } from 'src/domain/driverType';
import { showMessage } from 'src/utils';
import driverService from 'src/services/driverService';
import partnerService from 'src/services/partnerService';

const { Link } = Anchor;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    driverUId?: any;
    isSave?: any;
}



function callback(key: any) {
    console.log(key);
}


const DriverDataInfo: React.FC<IProps> = (props) => {

    const { id, userId, username, station, isSave, driverUId } = props;

    const [form] = Form.useForm();
    const [locations, setLocations] = useState<Location[]>([]);
    const [addsPathData, setAddsPathData] = useState<any>();
    const [locationCode, setLocationCode] = useState<any>();
    const [zipCodeData, setzipCodeData] = useState<any>();
    const [isUseflg, setUseflg] = useState<any>(false);
    const [useflgData, setUseflgData] = useState<any>(0);
    const [partners, setPartners] = useState([])
    const [driver, setDriver] = useState<Driver>({} as Driver);
    const [action, setAction] = useState(false);

    useEffect(() => {


        driverService.getBydrvUUId(driverUId)
        .then((res) => {
            setDriver(res.data)
        })

      

        driverService.getLocationAll().then((res) => {
            setLocations(res.data)
        })
        setPartners([]);
        renderPartners();
    }, []);


    useEffect(() => {
        console.log("driver >>",driver)
        if (driverUId === "") {
              console.log(driverUId)
        } else {
            if(driver.useFlg === 0) {
                setUseflg(true);
                setUseflgData(driver.useFlg)
            }
            setAction(true);
            setAddsPathData(driver.addrPath);
            setLocationCode(driver.locationCode);
            setzipCodeData(driver.zipcode);
            let effDateData = "";
            let endDateData = "";
            // if(driver.effDate !== ""){
            //     effDateData = moment(partner.effDate).format("YYYY/MM/DD") ;
            // }
            // if(partner.endDate!== ""){
            //     endDateData = moment(partner.endDate).format("YYYY/MM/DD") ;
            // }
            // setPartnerTypeValue(partner.partnerType);

            // console.log(partner.effDate,partner.endDate)

            form.setFieldsValue({
                // prenameId:driver.prenameId,
                firstname:driver.firstname,
                lastname:driver.lastname,
                address:driver.address,
                addrPath:driver.addrPathStr,
                tel:driver.tel,
                email: driver.email,
                identityId: driver.identityId,
                partnerUId: driver.partnerUId,
                driverUId : driver.driverUId,
                partnerUIdSrc:driver.partnerUId,
            });

            console.log(driver);
        }

    }, [driver]);

    const onSelectLocation = (data: any) => {
        console.log("onselect > ", data);
        let arr = data.split("/");
        // console.log(arr[3])

        let rs = locations.filter((item: any) =>
            item.zipcode === arr[3]
            && item.province === arr[2]
            && item.amphoe === arr[1]
            && item.tambon === arr[0])

        setAddsPathData(rs[0].provinceCode + "/" + rs[0].amphoeCode + "/" + rs[0].tambonCode);
        setLocationCode(rs[0].tambonCode);
        setzipCodeData(rs[0].zipcode);

    };

    const renderPartners = async () => {
        const res = await partnerService.getPartnerIsUseFlg(true);
        if (res.status === 200) {
            const data = await res.data;
            console.log(data)
            setPartners(partners => partners.concat(data));
        } else {
            showMessage('error status:' + res.status + '' + res.statusText);
        }
    }

    const switchOnChange = (checked:any) => {
        console.log(`switch to ${checked}`);
        setUseflg(checked);
        if(checked === false){
            setUseflgData(-1)
        }else{
            setUseflgData(0)
        }
        console.log(useflgData);
    };


    const onFinish = async (values: any) => {

        let effDateData = "";
        let endDateData = "";
        // console.log("enddate >>> "+values.endDate)
        // if(values.effDate !== undefined && values.effDate !==""){
        //     effDateData = moment(values.effDate).format('YYYYMMDD');
        // }

        // if(values.endDate !== undefined&& values.endDate !==""){
        //     endDateData = moment(values.endDate).format('YYYYMMDD');
        // }

        const _driver: Driver = {

            id: id,
            driverUId: driverUId,
            firstname: values.firstname,
            lastname: values.lastname,
            partnerUId: form.getFieldValue('partnerUIdSrc'),
            address: values.address,
            addrPath: addsPathData,
            tel: values.tel,
            email: values.email,
            identityId: values.identityId,
            locationCode: locationCode,
            zipcode: zipCodeData,
            useFlg: useflgData,

            userIdLogin: userId,
            usernameLogin: username,
            savStation: station,

        };

         console.log(_driver);


        if (driverUId === "") {

            try {
                driverService.addDriver(_driver).then(res => {
                    console.log("adddrv",res);
                    if(res.data.status){
                        message.success("save success", 1.5);
                        isSave(true);
                    }else{
                        message.error(res.data.message, 1.5);
                    }
                    
                }).catch(err => {
                    console.log(err);
                });
            } catch (err: any) {
                showMessage(err);
            }


        }

        if (driverUId !== "") {
            console.log(_driver)
            driverService.updateDriver(_driver).then(res => {
                if(res.data.status){
                    message.success("update complete", 1.5);
                    // isSave(true);
                }else{
                    message.error(res.data.message, 1.5);
                }
            }).catch(err => {
                console.log(err)
            });
        }
        // setTrigger(false);
    };


    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <Section >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                // initialValues={{ requiredMarkValue: requiredMark }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={requiredMark}
                >
                    <Row gutter={[10, 10]}>
                       <Col span={2}></Col>
                        <Col span={20}>
                            <Row gutter={[20, 10]}>
                                <Col span={12}>
                                    <Form.Item label='ชื่อคนขับรถ' name='firstname' 
                                     rules={[{ required: true, message: 'กรอกชื่อคนขับรถ' }]}
                                    //  required tooltip="This is a required field"
                                     >
                                        <Input placeholder="" />
                                    </Form.Item>

                                </Col>
                                <Col span={12}>
                                    <Form.Item label='นามสกุล' name='lastname' 
                                    // rules={[{ required: true, message: 'กรอกชื่อนามสกุล' }]}
                                    // required tooltip="This is a required field"
                                    >
                                        <Input placeholder="" />
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item label='บ้านเลขที่' name='address' 
                                    // required tooltip="This is a required field"
                                    >
                                        <Input placeholder="บ้าน/ซอย/ถนน" />
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item label='ที่อยู่' name='addrPath' 
                                    // required tooltip="This is a required field"
                                    >
                                        <AutoComplete
                                            onSelect={onSelectLocation}
                                            // style={{ width: '80%' }}
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษรีย์"
                                        >
                                            {locations.map((item) =>
                                                <Option
                                                    value={item.addrPath}
                                                    key={item.id}
                                                    label={item.addrPath}>

                                                    {item.addrPath}

                                                </Option>
                                            )}
                                        </AutoComplete>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[10, 10]}>
                                <Col span={12}>
                                    <Form.Item label='เลขที่บัตรประชาชน' name='identityId' 
                                    // rules={[{ max:13, required: true, message: 'กรอกเลขที่บัตรประชาชน' }]}
                                    // required tooltip="This is a required field"
                                    >
                                        <Input placeholder="" maxLength={13} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label='เบอร์โทร' name='tel' 
                                        rules={[{ max:10, required: true, message: 'กรอกเบอร์โทร' }]}
                                    //    required tooltip="This is a required field"
                                       >
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label='อีเมล์' name='email'
                                    //  required tooltip="This is a required field"
                                     >
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label='เจ้าของรถ' name='partnerUIdSrc' 
                                     rules={[{ required: true, message: 'เลือกเจ้าของรถ' }]}
                                    //  required tooltip="This is a required field"
                                     >
                                        <Select
                                            filterOption={true}
                                            placeholder="เลือกเจ้าของรถ"
                                            optionFilterProp="children"
                                            showSearch
                                        >
                                            {partners.map((item: any) => (
                                                <Option value={item.value} key={item.partnerUId}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={19}></Col>
                                <Col span={4}>
                                    <Button
                                        size="large"
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        style={{ width: 120 }}
                                        onClick={()=> {form.submit()}}
                                    > 
                                        บันทึก
                                    </Button>
                                </Col>
                                <Col span={1}></Col>
                            </Row>


                        </Col>
                        {/* <Col span={1}></Col>
                        <Col span={8}> */}
                            {/* <div className={classes.divAddImg}> */}
                            {/* <Section>
                                <Row >
                                    <Col span={1}></Col>
                                    <Col span={22} style={{ marginTop: "1em", alignItems: "center" }} >
                                        <Image style={{ height: "250px", width: "250px" }} src={imageDriver} />
                                    </Col>
                                    <Col span={1}></Col>
                                </Row>
                                <Row style={{ marginTop: "1em" }}>
                                    <Col span={24}>

                                        <Row>

                                            <Col span={7} style={{ textAlign: "right" }}>
                                                <span >รหัส :</span>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14}>

                                                <span >{driverUId}</span>
                                            </Col>
                                            <Col span={2}></Col>
                                        </Row>
                                        <Row style={{ marginTop: "1em", marginBottom: "1em" }}>

                                            <Col span={7} style={{ textAlign: "right" }}>
                                                <span>สถานะ :</span>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={14} >
                                            <Switch onChange={switchOnChange} checked={isUseflg} />
                                            </Col>
                                            <Col span={2}></Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </Section> */}
                        {/* </Col> */}
                    </Row>

                </Form>

            </Section>


        </>
    )
}

export default DriverDataInfo