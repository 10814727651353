import React, { ReactNode, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  getSessionUserFromLocal,
} from "src/store/authen/action";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = (props) => {
    const { children } = props;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { sessionUser } = useAppSelector((state) => state.auth);
  
    useEffect(() => {
      dispatch(getSessionUserFromLocal());
  
      if (!sessionUser.username) {
        history.replace("/login");
      }
    }, []);
  
    return <>{children}</>;
  };
  
  export default AuthGuard;