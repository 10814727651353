import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { ThunkAction } from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";



const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<
  Promise<void>,
  RootState,
  void,
  Action<string>
>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useAppDispatch = () => useReduxDispatch<AppDispatch>();

// export type ThunkPromiseAction<C, P> = (
//   dispatch: any,
//   state: RootState
// ) => (command: C) => Promise<P>;

// export const thunkPromise = <T, C>(
//   fnAction: ThunkPromiseAction<C, T>,
//   command: C
// ): AppThunk<T> => {
//   return async (dispatch, getState): Promise<T> => {
//     return fnAction(dispatch, getState())(command);
//   };
// };


export default store;
