import type { AppThunk } from "../index";
import { SessionUser } from "./types";
import {
  setSessionUser as setSessionUserAction,
  getSessionUserFromLocal as getSessionUserFromLocalAction,
} from "./slice";

export const getSessionUserFromLocal = ():AppThunk =>
  async (dispatch:any): Promise<void> => {
    const sessionUserString = localStorage.getItem("sessionUser");
    console.log("sessionUserString", sessionUserString);

    if(!sessionUserString) return;

    const sessionUser : SessionUser = JSON.parse(sessionUserString);

    // console.log("username, email", username, email);
    // const sessionUser: SessionUser = {
    //   username: username as string,
    //   email: email as string,
    //   // appName :string;
    //   // fullname : string;
    //   // userUId :string;

    // };
    sessionUser.station="HO"
    dispatch(getSessionUserFromLocalAction(sessionUser));
  };

export const removeSessionUser  = (): AppThunk =>
async (dispatch): Promise<void> => {

  localStorage.clear();
  // const  sessionUser = {username: "",appName:"",fullname :"",userUId:"", email: "",station:"HO" }
  const sessionUser :SessionUser = {
    userId:0,
    username: "",
    appName:"",
    fullname :"",
    userUId: "",
    email: "",
    station:"HO" }
  dispatch(setSessionUserAction(sessionUser));

}

export const setSessionUser =
  (sessionUser: SessionUser): AppThunk =>
  async (dispatch): Promise<void> => {
    localStorage.setItem("sessionUser", JSON.stringify(sessionUser));

    console.log("action::setSessionUser", sessionUser);
    dispatch(setSessionUserAction(sessionUser));
  };
