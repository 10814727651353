import { createRoot } from 'react-dom/client';
import App from "src/App";
import store from "./store";
import { Provider as ReduxProvider } from "react-redux";
import './index.css';
import { ConfigProvider } from 'antd';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
	<>
		<ReduxProvider store={store}>
			<ConfigProvider
        componentSize="large"
        locale={{ locale: "th"}}
        theme={{ token: {
          colorPrimaryText: "#0119F5",
          colorPrimaryTextActive: "#0119F5",
          colorPrimary: "#1B25F5",
          colorLink: "#1B25F5",
          colorLinkActive: "#1B25F5",
          colorLinkHover: "#1B25F5",
          borderRadius:5,
          fontFamily: 'Anuphan'}}}>
			<App />
			</ConfigProvider>
    </ReduxProvider>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
