
import { Table, Input, Button, Tag, Avatar, Image, Row, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from "@ant-design/icons";
import { Content } from 'antd/lib/layout/layout';

import { useHistory } from 'react-router';
import classes from "../owner/master.module.css";
import { Driver } from 'src/domain/driverType';
import Section from 'src/components/container/Section';
import DriverDataInfo from './DriverDataInfo';
import driverService from 'src/services/driverService';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/store';

const { Search } = Input;


const columns = [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
  },
  {
    title: 'ชื่อพนักงานขับรถ',
    dataIndex: 'fullname',
    render: (value: string, item: Driver, index: number) => <Link to={`/app/driver/edit/${item.driverUId}`}>{value}</Link>,
  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'tel',
  },
  {
    title: 'บัตรประชาชน',
    dataIndex: 'identityId',
  },
  {
    title: 'เจ้าของรถ',
    dataIndex: 'partnerUId',
  },
  {
    title: 'สถานะ',
    dataIndex: 'useFlg',
    render: (useFlg: number) => (
      <>
        <div>
          {(() => {
            if (useFlg === 0) {
              return (
                <Tag color="green">{"ใช้งาน"}</Tag>
              )
            } else {
              return (
                <div
                // style={{ background: "red" }}
                >
                  <Tag color="red">{"ไม่ใช้งาน"}</Tag>
                </div>
              )
            }
          })()}
        </div>
      </>
    ),
  },
];

// const rowSelection = {
//   onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
// }

const DriverList = () => {

  // const history = useHistory();
  // const onSearch = (value: any) => console.log(value);
  const { sessionUser } = useAppSelector((state) => state.auth);
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const [driverList, setDriverList] = useState<Driver[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {

    driverService.getByCondition("")
      .then((res) => {
        setDriverList(res.data)
      })
  }, []);

  useEffect(() => {
    driverService.getByCondition("")
      .then((res) => {
        setDriverList(res.data)
      })
  }, [isModalVisible]);

  const onSearch = (value: any) => {
    console.log("onsearch", value)

    driverService.getByCondition(value)
      .then((res) => {
        setDriverList(res.data)
      })

  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <>
      <PageHeader title="ข้อมูลพนักงานรถ">

        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          style={{ width: 120 }}
          onClick={() => setIsModalVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>

      <Section style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
        <Row gutter={[20, 10]}>
          <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200 }} />
        </Row>
      </Section>

      <Section style={{ marginTop: 30 }}>
        <Table
          style={{ marginTop: "1em", }}
          rowKey={(item: Driver) => item.id}
          dataSource={driverList}
          columns={columns}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, }}
        />
      </Section>


      <Modal
        title="เพิ่มข้อมูล พนักงานขับรถ "
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        destroyOnClose
      >
        <div>
          <DriverDataInfo
            id={0}
            userId={sessionUser.userId}
            username={sessionUser.username}
            station={sessionUser.station!}
            driverUId={""}
            isSave={() => { handleCancel(); }}
          />
        </div>
      </Modal>
    </>
  )
}

export default DriverList
