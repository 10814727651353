import { Button, Col, Row, Space,Input, Form } from 'antd'
import React, { useState } from 'react'
import { FilterItem } from '../../components/filter/filterItem';

interface IProps {
  onOK:(filterItem:FilterItem)=>void
}


const SearchJoborderPopup : React.FC<IProps> = (props) =>  {

  const [form] = Form.useForm();
  const[jobNo , setJobNo] = useState("");

  const handleOnClick =()=>{

     const filterItem : FilterItem ={
       name:"joborder",
       message:'เลขที่ใบงาน :'+ jobNo,
       payload:{
         jobNo: jobNo,
       }

     }
     props.onOK(filterItem);
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
    <Form
         // {...formItemLayout}
         // layout={formLayout}
         form={form}
         onFinish={handleOnClick}
         // initialValues={{ layout: formLayout }}
         // onValuesChange={onFormLayoutChange}
      >
     <Form.Item
        // label="Username"
        name="jobNo"
        rules={[{ required: true, message: 'กรอกเลขที่ใบงาน' }]}
      >
         <Input placeholder="เลขที่ใบงาน" onChange={(e)=>setJobNo(e.target.value)} value={jobNo} />
      </Form.Item>

      </Form>

    <Space direction="vertical" >
        {/* <Row>
          <Col>
            <Input placeholder="เลขที่ใบงาน" onChange={(e)=>setJobNo(e.target.value)} value={jobNo} />
          </Col>
        </Row> */}
        <Row>
          <Col>
          <Space>
            <Button onClick={onReset}>ล้าง</Button>
            <Button type="primary" danger onClick={()=>{form.submit()}}>ok</Button>
            </Space>
          </Col>
        </Row>
      </Space>
    </>
  )
}

export default SearchJoborderPopup