import { DownloadOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import React, { FC, JSXElementConstructor } from 'react'
import styles from './FilterSubject.module.css';


interface FilterSubjectProps {
  subjectName: string;
  label: string;
  overlay : React.ReactElement;
  visible : boolean | undefined;
  className? : string | undefined;
  onActive : (subjectName: string, isActive: boolean) => void;
  width?: string | number;
  checkFiltered?: (subjectName: string) => boolean
}


const FilterSubject: FC<FilterSubjectProps> = (props) => {

  const { subjectName, label, overlay : overlayComponent, visible, className, onActive, width, checkFiltered } = props;

  const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    onActive(subjectName, !visible);
  }

  const FilterTitle = (props:any) => {
    return <span className={styles.filterTitle}>{props.children}</span>
  }

  return (
    <Dropdown
      overlay={overlayComponent}
      visible={visible}
      className={className}
      trigger={['click']} >
      <div
        className={checkFiltered && !checkFiltered(subjectName) ? styles.labelWrapper : styles.labelFilteredWrapper}
        style={{ cursor: 'pointer', width: width  }}
        onClick={handleOnClick}
        >
          <FilterTitle>{label}</FilterTitle>
          {visible ? <UpOutlined className={styles.arrow} /> : <DownOutlined className={styles.arrow} />}
      </div>
    </Dropdown>
  )
}

FilterSubject.defaultProps = {
  width : "100px"
}

export default FilterSubject