import { ColumnsType } from "antd/lib/table";
import React, { useState, useEffect } from "react";
import { SharingColumn } from './SharingData';
import { Table } from 'antd';
import BookingHead from 'src/model/truckmatching/BookingHead';
import { TableRowSelection } from "antd/lib/table/interface";

interface IProps {
    rowDatas?: BookingHead[],
}

const JobListConfirmTab: React.FC<IProps> = (props) => {
    const [currentJobList, setCurrentJobList] = useState<BookingHead[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>();

    const columns: ColumnsType<BookingHead> = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            width: 50,
            render: (value: string, item: BookingHead, index: number) => (
                ((currentPage - 1) * pageSize) + (index+1)
            ),
        },
        ...SharingColumn,
        {
            title: "Partner",
            dataIndex: "id1",
            key: "id1",
            width: 50,
            render: (value: string, item: BookingHead, index: number) => (
                value
            ),
        },
        {
            title: "ทะเบียนรถ",
            dataIndex: "id2",
            key: "id2",
            width: 50,
            render: (value: string, item: BookingHead, index: number) => (
                value
            ),
        },
        {
            title: "พขร.",
            dataIndex: "id3",
            key: "id3",
            width: 50,
            render: (value: string, item: BookingHead, index: number) => (
                value
            ),
        },
    ];

    useEffect(() => {

    }, []);

    useEffect(() => {
        setCurrentJobList(props.rowDatas ? props.rowDatas : []);
    }, [props.rowDatas])


    const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: BookingHead[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection: TableRowSelection<BookingHead> = {selectedRowKeys, onChange: onSelectChange};
    return (
        <div>
            <Table
                columns={columns}
                dataSource={currentJobList}
                size="small"
                rowKey={(item: BookingHead) => item.id}
                // rowSelection={rowSelection}
                pagination={{current: currentPage, pageSize: pageSize, onChange: (page: number) => {
                    setCurrentPage(page);
                }}}
            />
        </div>
    );
};
export default JobListConfirmTab;
