import React, { useEffect, useState } from 'react';
import { Alert, Button, Layout, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Post } from 'src/domain/postType';
import postService from 'src/services/postService';
import { Subject } from 'src/domain/subjectType';
import GroupContainer from 'src/components/container/GroupContainer';
import subjectService from 'src/services/subjectService';
// import './MatchTruck.css'
import Carousel from 'src/components/container/Carousel';
import HorizontalCalendar, { IActivateDateData, IHorizonTalCalendarData } from 'src/components/container/HorizontalCalendar';
import styles from './TruckMatchingList.module.css';
import BookingItem from 'src/model/truckmatching/Booking';
import dateUtils from 'src/utils/dateUtils';
import BookingTruckType from 'src/model/truckmatching/BookingTruckType';
import TruckType from 'src/model/truckmatching/TruckType';
import truckMatchService from "../../services/truckMatchService";
import BookingHead, { BookingHeadStatus } from 'src/model/truckmatching/BookingHead';

const columns = [
    {
        title: "Code",
        dataIndex: "id",
        key: "id",
        
    },
    {
        title: "Subject",
        dataIndex: "name",
        key: "name",
        render: (value: string,item:Subject,index:number) => <Link to={`/app/subject/view/${item.id}`}>{value}</Link>,
    },
    {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Class",
        dataIndex: "level",
        key: "level",
        
    },

];

const items1 = [
    {
        id: 1,
        name: "26/08/64",
        date: "20210826"
    },
    {
        id: 2,
        name: "27/08/64",
        date: "20210827"
    },
    {
        id: 3,
        name: "28/08/64",
        date: "20210828"
    },
    {
        id: 4,
        name: "29/08/64",
        date: "20210829"
    },
    {
        id: 5,
        name: "30/08/64",
        date: "20210830"
    },
    {
        id: 6,
        name: "31/08/64",
        date: "20210831"
    },
    {
        id: 7,
        name: "01/09/64",
        date: "20210901"
    },
    {
        id: 8,
        name: "02/09/64",
        date: "20210902"
    },
    {
        id: 9,
        name: "03/09/64",
        date: "20210903"
    },
    {
        id: 10,
        name: "04/09/64",
        date: "20210904"
    }
]
const items2 = [
    {
        id: 1,
        name: "B640800001",
        dateValue: "20211227",
    },
    {
        id: 2,
        name: "B640800002",
        dateValue: "20211226",
    },
    {
        id: 3,
        name: "B640800003",
        dateValue: "20211225",
    },
    {
        id: 4,
        name: "B640800004",
        dateValue: "20211227",
    },
    {
        id: 5,
        name: "B640800005",
        dateValue: "20211229",
    },
    {
        id: 6,
        name: "B640800006",
        dateValue: "20211230",
    },
    {
        id: 7,
        name: "B640800007",
        dateValue: "20220104",
    },
    {
        id: 8,
        name: "B640800008",
        dateValue: "20220104",
    },
    {
        id: 9,
        name: "B640800009",
        dateValue: "20220105",
    },
    {
        id: 10,
        name: "B640800010",
        dateValue: "20220106",
    }
];


const TruckMatchingList = () => {

    const [subject,setSubject] = useState([]);
    const [reservNoScrollRef, setReservNoScrollRef] = useState<HTMLOListElement>();
    const [reservDateScrollRef, setReservDateScrollRef] = useState<HTMLDivElement>();
    const [calendarItems, setCalendarItem] = useState<Array<IHorizonTalCalendarData>>([]);
    const [selectedReceiveNoIndex, setSelectedReceiveNoIndex] = useState<number | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<{selectedDateIndex: number, selectedTruckIndex: number}>({selectedDateIndex: 0, selectedTruckIndex: 0});
    const [bookingList, setBookListList] = useState<BookingItem[]>([]);
    const [calendaDate, setCalendarDate] = useState<{firstDate: Date, lastDate: Date}>({firstDate: new Date(), lastDate: new Date()});
    const [bookingTruckTypeList, setBookingTruckTypeList] = useState<BookingTruckType[]>([]);
    const [isBookingTruckDateChanged, setIsBookingTruckDateChanged] = useState<boolean>(false);
    const [focusDate, setFocusDate] = useState<Date>(new Date());
    const [receiveNoList, setReceiveNoList] = useState<BookingHead[]>([]);
    const [isReceiveNoListChanged, setReceiveNoListChanged] = useState<boolean>(false);
    const [testList, setTestList] = useState<BookingHead[]>([]);

    useEffect(() => {
        // loadBookingData();
        // createCalendarItem();
        let lastDate = new Date();
        let fromDate = calculateFromDate(lastDate, 15);
        setCalendarDate({firstDate: fromDate, lastDate: lastDate});
    },[setCalendarDate]);

    useEffect(() => {
        // createCalendarItem();
    }, [bookingList]);

    useEffect(() => {
        loadBookingData();
    }, [calendaDate]);

    useEffect(() => {
        // setIsBookingTruckDateChanged(false);
        loadReceiveNo(selectedIndex.selectedDateIndex, selectedIndex.selectedTruckIndex);
        setTimeout(() => {
            setIsBookingTruckDateChanged(true);
        }, 300);
    }, [bookingTruckTypeList]);

    useEffect(() => {
        // setIsBookingTruckDateChanged(false);
        if(receiveNoList != null && receiveNoList.length > 0){
            setFocusDate(dateUtils.convertDBDateStr2Date(receiveNoList[0].startDate));
        }
        setReceiveNoListChanged(true);
    }, [receiveNoList]);

    useEffect(() => {
        if(isReceiveNoListChanged){
            createCalendarItem();
        }
    }, [isReceiveNoListChanged]);

    useEffect(() => {
        loadReceiveNo(selectedIndex.selectedDateIndex, selectedIndex.selectedTruckIndex);
    }, [selectedIndex]);

    const createBookingTruckType = (bookingItemList: BookingItem[]) => {
        setIsBookingTruckDateChanged(false);
        let dictionary: any = {};
        for(let i = 0; i < bookingItemList.length; i++){
            let data = bookingItemList[i];
            if(!dictionary[data.bookDate]){
                dictionary[data.bookDate] = new BookingTruckType(data.bookDate, []);
            }
            let currBookingTruckType: BookingTruckType = dictionary[data.bookDate];
            // console.log("currBookingTruckType: ", currBookingTruckType);
            currBookingTruckType.truckTypeList.push(new TruckType(data.truckTypeId, data.truckTypeName, data.countTruckType, data.bookingHeadList))
        }

        let dicKeys = Object.keys(dictionary);
        let result: BookingTruckType[] = [];
        if(dicKeys.length > 0){
            let sortDicKeys = dicKeys.sort((a, b) => {return a.localeCompare(b)});
            for(let i = 0; i < sortDicKeys.length; i++){
                result.push(dictionary[sortDicKeys[i]]);
            }
        }
        setBookingTruckTypeList(result);
    }

    const loadBookingData = () => {
        if(calendaDate && calendaDate.firstDate && calendaDate.lastDate){
            let fromDate = dateUtils.convertDate2DBDateFormat(calendaDate.firstDate);
            let endDate = dateUtils.convertDate2DBDateFormat(calendaDate.lastDate);
            truckMatchService.getByBookingDate(fromDate, endDate).then((res) => {
                // console.log("loadBookingData: ");
                if(res && res.data){
                    // console.log(res.data);
                    let data: BookingItem[] = res.data;
                    createBookingTruckType(data);
                }
            });
        }
    }

    const calculateFromDate = (focusDate: Date, diffValue: number) => {
        let fromDate = new Date(focusDate.getTime());
        fromDate.setDate(fromDate.getDate() - diffValue);
        return fromDate;
    }

    const updateDate = (firstDate: Date, lastDate: Date) => {
        if(calendaDate && calendaDate.firstDate && calendaDate.lastDate){
            if(dateUtils.getDiffDate(calendaDate.firstDate, firstDate) != 0 || dateUtils.getDiffDate(calendaDate.lastDate, lastDate)){
                setCalendarDate({firstDate: firstDate, lastDate: lastDate});
            }
        }else{
            setCalendarDate({firstDate: firstDate, lastDate: lastDate});
        }
    }

    const createCalendarItem = () => {
        if(bookingTruckTypeList){
            let items: Array<IHorizonTalCalendarData> = [];
            for(let i = 0; i < receiveNoList.length; i++){
                let activateDate:IActivateDateData = {
                    fromDate: receiveNoList[i].startDate,
                    toDate: receiveNoList[i].endDate,
                    diffDate: dateUtils.getDiffDate(dateUtils.convertDBDateStr2Date(receiveNoList[i].startDate), dateUtils.convertDBDateStr2Date(receiveNoList[i].endDate)),
                    dataValue: receiveNoList[i]
                }
                let activateDateList = [activateDate]
                let item: IHorizonTalCalendarData = {
                    id: receiveNoList[i].id,
                    activateDate: activateDateList,
                    itemValue: receiveNoList[i]
                }
                items.push(item);
            }
            setCalendarItem(items);
        }else{
            setCalendarItem([]);
        }
    }

    const history = useHistory();
    const { Content } = Layout;

    const onChange = () => {

    }
    const onClickItem = () => {

    }
    const onClickThumb = () =>{

    }
    const onCarouselScroll = (scrollTop: number) => {
        console.log("onCarouselScroll", scrollTop);
        if(reservDateScrollRef){
            console.log("reservDateScrollRef.scrollTop: ", reservDateScrollRef.scrollTop);
            reservDateScrollRef.scrollTop = scrollTop;
        }
    }
    const onCalendarScroll = (scrollTop: number) => {
        console.log("onCalendarScroll", scrollTop);
        if(reservNoScrollRef){
            console.log("reservNoScrollRef.scrollTop: ", reservNoScrollRef.scrollTop);
            reservNoScrollRef.scrollTop = scrollTop;
        }
    }
    const onTruckTypeItemClicked = (data: any, itemIndex: number, nestedIndex: number) => {
        console.log("onTruckTypeItemClicked: ", data);
        setSelectedIndex({selectedDateIndex: itemIndex, selectedTruckIndex: nestedIndex});
        // setSelectedReceiveNoIndex(null);
        // loadReceiveNo(itemIndex, nestedIndex);
    }
    const loadReceiveNo = (bookingDateIndex: number, truckTypeIndex: number) => {
        if(bookingDateIndex == null || truckTypeIndex == null){
            setReceiveNoListChanged(false);
            setReceiveNoList([]);
            return;
        }
        let bookingTruckType: BookingTruckType = bookingTruckTypeList[bookingDateIndex];
        if(bookingTruckType == null || bookingTruckType.truckTypeList == null|| bookingTruckType.truckTypeList[truckTypeIndex] == null){
            setReceiveNoListChanged(false);
            setReceiveNoList([]);
            return;
        }
        let truckType = bookingTruckType.truckTypeList[truckTypeIndex];
        if(truckType.bookingHeadList != receiveNoList){
            setReceiveNoListChanged(false);
            setSelectedReceiveNoIndex(null);
        }
        setReceiveNoList(truckType.bookingHeadList == null ? [] : truckType.bookingHeadList);
    }
    const checkSelectedStyle = (currentDateIndex: number, currentTruckIndex: number) => {
        const selectedStyle = { borderRadius: '2px', backgroundColor: '#268DDD', color: '#FAFAFA' };
        const nonSelectedStyle = {};
        return (selectedIndex.selectedDateIndex == currentDateIndex && selectedIndex.selectedTruckIndex == currentTruckIndex) ? selectedStyle : nonSelectedStyle
    }
    const renderHeaderName = (contentName: string) => {
        return (
            <b>{contentName}</b>
        );
    }
    const renderBody1 = (item: BookingTruckType, index: number) => {
        const regularStyle = { display: 'flex', justifyContent: 'center', maxWidth: '150px', width: '80%' };
        return (
            <GroupContainer header={renderHeaderName(dateUtils.convert2ShowDateFormat(item.bookingDate))}>
                {item.truckTypeList.map((data: TruckType, itemIndex: number) => {
                    return (
                        <div key={`${index}_${itemIndex}`} style={{...regularStyle, ...checkSelectedStyle(index, itemIndex)}} className={styles["canClickItem"]} onClick={() => {onTruckTypeItemClicked(item, index, itemIndex)}}>{data.truckTypeName} : {data.truckCount} ใบจอง</div>
                    );
                })}
                {/* <div style={{...regularStyle, ...checkSelectedStyle(index, 1)}} className={styles["canClickItem"]} onClick={() => {onTruckTypeItemClicked(item, index, 1)}}>10 ล้อคอก : 15</div>
                <div style={{...regularStyle, ...checkSelectedStyle(index, 2)}} className={styles["canClickItem"]} onClick={() => {onTruckTypeItemClicked(item, index, 2)}}>10 ล้อทึบ : 20</div> */}
            </GroupContainer>
        );
    }
    const renderBody1Test = (item: any, index: number) => {
        // console.log("renderBody1: ", item);
        const regularStyle = { display: 'flex', justifyContent: 'center', maxWidth: '150px', width: '80%' };
        return (
            <GroupContainer header={item.name}>
                <div style={{...regularStyle, ...checkSelectedStyle(index, 0)}} className={styles["canClickItem"]} onClick={() => {onTruckTypeItemClicked(item, index, 0)}}>5 ล้อคอก : 30</div>
                <div style={{...regularStyle, ...checkSelectedStyle(index, 1)}} className={styles["canClickItem"]} onClick={() => {onTruckTypeItemClicked(item, index, 1)}}>10 ล้อคอก : 15</div>
                <div style={{...regularStyle, ...checkSelectedStyle(index, 2)}} className={styles["canClickItem"]} onClick={() => {onTruckTypeItemClicked(item, index, 2)}}>10 ล้อทึบ : 20</div>
            </GroupContainer>
        );
    }
    const renderReceiveDate = (item: BookingHead) => {
        return `${dateUtils.convert2ShowDateFormat(item.startDate)} - ${item.endDate == null || item.endDate.trim() == "" ? "ไม่ระบุ" : dateUtils.convert2ShowDateFormat(item.endDate)}`
    }
    const renderBody2 = (item: BookingHead, index: number) => {
        let containerStyle: any = {cursor: 'pointer'};
        // if(selectedReceiveNoIndex != null && selectedReceiveNoIndex == index){
        //     containerStyle = { backgroundColor: '#268DDD', color: '#FAFAFA' };
        // }
        if(item.status != BookingHeadStatus.C00_RESERVE && item.status != BookingHeadStatus.C01_REQUEST){
            containerStyle.backgroundColor = "#48B800";
            containerStyle.color = "#FAFAFA";
        }
        let header: any = item.bookNo;
        if(item.status == BookingHeadStatus.C01_REQUEST){
            // if(true){
                header = (
                    <div style={{display: 'flex', }}>
                        {item.bookNo}
                        <img style={{width: '16px', height: '16px', marginLeft: '3px'}} src={"/assets/icons/notification-black.png"}></img>
                    </div>
                );
            }
        return (
            <GroupContainer header={header} containerStyle={containerStyle} tooltip={renderReceiveDate(item)}>
            </GroupContainer>
        );
    }
    const renderBody2Test = (item: any, index: number) => {
        let containerStyle: any = null;
        if(selectedReceiveNoIndex != null && selectedReceiveNoIndex == index){
            containerStyle = { backgroundColor: '#268DDD', color: '#FAFAFA',  };
        }
        return (
            <GroupContainer header={item.name} containerStyle={containerStyle}>
            </GroupContainer>
        );
    }
    const canMatching = () => {
        return bookingTruckTypeList != null && bookingTruckTypeList.length > 0 && selectedIndex.selectedDateIndex != null && selectedIndex.selectedTruckIndex != null;
    }
    const renderBookingTruckDate = () => {
        if(isBookingTruckDateChanged){
            if(bookingTruckTypeList == null || bookingTruckTypeList.length < 1){
                return (
                    <div>ไม่พบข้อมูล</div>
                );
            }
            return(
                <Carousel items={bookingTruckTypeList} renderBody={renderBody1} showItem={5}>
                </Carousel>
            );
        }
    }
    const renderReceiveNo = () => {
        if(isReceiveNoListChanged){
            if(receiveNoList == null || receiveNoList.length < 1){
                return (
                    <div>ไม่พบข้อมูล</div>
                );
            }
            return(
                <Carousel 
                    contentScrollRef={(ref: HTMLOListElement) => {setReservNoScrollRef(ref)}}
                    contentScrollLink={reservDateScrollRef}
                    items={receiveNoList} 
                    renderBody={renderBody2} 
                    showItem={10} 
                    onItemClicked={(itemData: BookingHead, clickedIndex: number) => {
                        // console.log("เลขที่การจอง: ", itemData); 
                        setFocusDate(dateUtils.convertDBDateStr2Date(itemData.startDate));
                        if(clickedIndex != selectedReceiveNoIndex){
                            setSelectedReceiveNoIndex(clickedIndex);
                        }else{
                            setSelectedReceiveNoIndex(null);
                        }
                    }}
                    // onContentScroll={onCarouselScroll}
                >
                </Carousel>
            );
        }
    }
    const renderDateBody = (date: Date, item: IHorizonTalCalendarData, rowIndex: number, index: number, dataValue: any) => {
        let dateValueStr = dateUtils.convertDate2DBDateFormat(date);
        let itemValue: BookingHead = item.itemValue;
        let status = itemValue.status;
        let bgColor = status != BookingHeadStatus.C00_RESERVE && status != BookingHeadStatus.C01_REQUEST ? "rgba(72, 184, 4, 0.3)" : "rgba(190, 190, 190, 0.3)";
        let borderLine = status != BookingHeadStatus.C00_RESERVE && status != BookingHeadStatus.C01_REQUEST ? "rgba(2, 138, 4, 1) 2px solid" : "rgba(160, 160, 160, 1) 2px solid";
        return (
            <div style={{display: 'flex', width: '100%', height: '100%', borderRadius: '0px', border: borderLine, color: 'black', backgroundColor: bgColor, alignItems: 'center', justifyContent: 'center', borderWidth: '2px', zIndex: index}}>
                {itemValue.bookNo}
            </div>
        );
    }
    return (
        <div>
            <PageHeader title="จับคู่รถ">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => { 
                        if(canMatching()){
                            history.push(`/app/matching/manage/${bookingTruckTypeList[selectedIndex.selectedDateIndex].bookingDate}/${bookingTruckTypeList[selectedIndex.selectedDateIndex].truckTypeList[selectedIndex.selectedTruckIndex].truckTypeId}`);
                        }else{
                            alert("กรุณาเลือกบิลที่ต้องการดำเนินการก่อน");
                        } 
                    }} 
                >
                    จับคู่
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div className={styles["contentBody"]} style={{minHeight: '300px'}}>
                    <div className={styles["rowBody"]}>
                        <div style={{flex: 1, margin: "5px 25px 5px 5px",}} className={`${styles["columnBody"]} ${styles["radiusBody"]}`}>
                            <GroupContainer header="วันที่จอง">
                                {renderBookingTruckDate()}
                            </GroupContainer>
                        </div>
                        <div style={{flex: 3, margin: "5px 5px 5px 0px",}} className={`${styles["rowBody"]} ${styles["radiusBody"]}`}>
                            <div style={{flex: 1}} className={styles["columnBody"]}>
                                <GroupContainer header="เลขที่การจอง">
                                    {renderReceiveNo()}
                                </GroupContainer>
                            </div>
                            <div style={{width: 2, margin: "10px 0px 10px 0px", backgroundColor: "#DFDFDF", borderRadius: 5,}}>
                    
                            </div>
                            <div style={{flex: 3,}} className={styles["columnBody"]}>
                                <GroupContainer header="วันที่ใช้งาน">
                                    <HorizontalCalendar 
                                        contentScrollRef={(ref: HTMLDivElement) => {setReservDateScrollRef(ref)}}
                                        contentScrollLink={reservNoScrollRef}
                                        items={calendarItems} 
                                        renderCellBody={renderDateBody} 
                                        showDate={8} 
                                        focusDate={focusDate}
                                        showItem={10}
                                        // updateDate={updateDate}
                                        // onContentScroll={onCalendarScroll}
                                        // correctColorCode="red"
                                    >
                                        
                                    </HorizontalCalendar>
                                </GroupContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default TruckMatchingList;
