import http from "../lib/http";
import { rootUrl } from "./baseUrl";
const url = rootUrl("SECURITY") + "secuser/"

const  login = ({username,password,appName}: any) => {
    console.log("login >>> ", {username,password,appName})
    return http.post(url+"login", {username,password,appName});
  };

  export default{
    login
}