import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  setSessionUser,
  getSessionUserFromLocal,
} from "src/store/authen/action";
import "./Login.css";

import secUserService from "src/services/secUserService";
import Section from "../components/container/Section";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function validate(username: string, password: string): string {
  if (!(username === "admin" && password === "adminadmin")) {
    return "Incorrect username or password";
  }

  return "";
}

const LoginForm = () => {
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sessionUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSessionUserFromLocal());
    console.log("sessionUser", sessionUser);

    if (sessionUser.username) history.replace("/app");
  }, []);

  const onFinish = (values: any) => {
    console.log("form", values);
    const { username, password } = values;

    secUserService.login({username,password,appName:"FLEET"}).then((res)=>{
    console.log("login onfinish >>>>",res.data);
    if(!res.data.status){
      setError(res.data.message);
      return;
    }

    const sessionUser = res.data.body;
    dispatch(setSessionUser(sessionUser));
    history.replace("/app");

    })
  };

  const onFinishFailed = (errorInfo:any) => {
    console.log("error x", errorInfo);
  };

  return (
		<>
			<Row justify="center" align="middle" style={{ height: "100vh", backgroundColor: "#EAEBEC" }}>
				<Col xs={24} sm={18} md={14} lg={12} xl={10}>
					<Section style={{ padding: 20, minWidth: 350, maxWidth: 600 }}>
            <Typography.Title level={3} style={{ marginTop: 0, marginBottom: 5 }}>
              Login
            </Typography.Title>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              log in to RengerMove:  Fleet Management System
            </Typography.Text>
            <br />
					  <br />
						{error && (
							<Alert
								message="Error"
								description={error}
								type="error"
								closable
								// onClose={onClose}
							/>
						)}
						<br />
						<Form
							name="login"
              layout="vertical"
							initialValues={{ remember: true }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
              size="large"
						>
							<Form.Item
								label="Username"
								name="username"
								rules={[{ required: true, message: "Please input your username!" }]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label="Password"
								name="password"
								rules={[{ required: true, message: "Please input your password!" }]}
							>
								<Input.Password />
							</Form.Item>

							<Form.Item name="remember" valuePropName="checked">
								<Checkbox>Remember me</Checkbox>
							</Form.Item>

							<Form.Item>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Section>
				</Col>
			</Row>
		</>
	);
};

export default LoginForm;
