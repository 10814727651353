import * as WebConfig from "../constant/WebConfig";

export type urlType ="SECURITY"|"FLEET"

export const rootUrl = (url:urlType) =>{
    if(url === "SECURITY"){
        return WebConfig.securityUrl
    }

    if(url === "FLEET"){
        return WebConfig.fleetUrl
    }
}