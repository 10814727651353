import React, { useEffect, useState } from 'react';
import { Alert, Button, Image, Layout, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Post } from 'src/domain/postType';
import postService from 'src/services/postService';
import { Subject } from 'src/domain/subjectType';
import GroupContainer from 'src/components/container/GroupContainer';
import subjectService from 'src/services/subjectService';
// import './MatchTruck.css'
import Carousel from 'src/components/container/Carousel';
import HorizontalCalendar, { IHorizonTalCalendarData, IActivateDateData } from 'src/components/container/HorizontalCalendar';
import styles from './TruckMatchingList.module.css';
import DateFormat from 'src/utils/dateUtils';
import { keyframes } from 'styled-components';
import truckMatchService from "../../services/truckMatchService";
import Truck from 'src/model/truckmatching/Truck';
import { isConstructorDeclaration } from 'typescript';
import BookingHead from 'src/model/truckmatching/BookingHead';
import TruckDTO from 'src/model/truckmatching/TruckDTO';

const columns = [
    {
        title: "Code",
        dataIndex: "id",
        key: "id",
        
    },
    {
        title: "Subject",
        dataIndex: "name",
        key: "name",
        render: (value: string,item:Subject,index:number) => <Link to={`/app/subject/view/${item.id}`}>{value}</Link>,
    },
    {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Class",
        dataIndex: "level",
        key: "level",
        
    },

];

const items1 = [
    {
        id: 1,
        name: "26/08/64"
    },
    {
        id: 2,
        name: "27/08/64"
    },
    {
        id: 3,
        name: "28/08/64"
    },
    {
        id: 4,
        name: "29/08/64"
    },
    {
        id: 5,
        name: "30/08/64"
    },
    {
        id: 6,
        name: "31/08/64"
    },
    {
        id: 7,
        name: "01/09/64"
    },
    {
        id: 8,
        name: "02/09/64"
    },
    {
        id: 9,
        name: "03/09/64"
    },
    {
        id: 10,
        name: "04/09/64"
    }
]
const items2 = [
    {
        id: 1,
        name: "B640800001",
        fromDate: "20211227",
        toDate: "20211228",
        status: 'DONE'
    },
    {
        id: 2,
        name: "B640800002",
        fromDate: "20211226",
        toDate: "20211228",
        status: 'DRAFT'
    },
    {
        id: 3,
        name: "B640800003",
        fromDate: "20211225",
        toDate: "20211225",
        status: 'DRAFT'
    },
    {
        id: 4,
        name: "B640800004",
        fromDate: "20211225",
        toDate: "20211228",
        status: 'DRAFT'
    },
    {
        id: 5,
        name: "B640800005",
        fromDate: "20211230",
        toDate: "20220102",
        status: 'WAITING'
    },
    {
        id: 6,
        name: "B640800006",
        fromDate: "20211230",
        toDate: "20211230",
        status: 'WAITING'
    },
    {
        id: 7,
        name: "B640800007",
        fromDate: "20220104",
        toDate: "20220105",
        status: 'WAITING'
    },
    {
        id: 8,
        name: "B640800008",
        fromDate: "20220105",
        toDate: "20220105",
        status: 'WAITING'
    },
    {
        id: 9,
        name: "B640800009",
        fromDate: "20220105",
        toDate: "20220107",
        status: 'DONE'
    },
    {
        id: 10,
        name: "B640800010",
        fromDate: "20220104",
        toDate: "20220110",
        status: 'DRAFT'
    },
    {
        id: 11,
        name: "B640800011",
        fromDate: "20211230",
        toDate: "20220102",
        status: 'WAITING'
    }
]

const items3 = [
    {
        id: 1,
        name: "ชม 70-1234",
        dateValue: "20211227",
        itemValue: {
            id: 1,
            name: "B640800001",
            fromDate: '20211227',
            toDate: '20211229',
        },
    },
    {
        id: 2,
        name: "ชม 70-1235",
        dateValue: "20211226",
        itemValue: {
            id: 2,
            name: "B640800002",
            fromDate: '20211226',
            toDate: '20211227',
        }
    },
    {
        id: 3,
        name: "ชม 70-1236",
        dateValue: "20211227",
        itemValue: {
            id: 3,
            name: "B640800003",
            fromDate: '20211229',
            toDate: '20211230',
        }
    },
    {
        id: 4,
        name: "ชม 70-1237",
        dateValue: "20211229",
        itemValue: {
            id: 4,
            name: "B640800004",
            fromDate: '20211230',
            toDate: '20220103',
        }
    },
    {
        id: 5,
        name: "ชม 70-1238",
        dateValue: "20211230",
        itemValue: {
            id: 5,
            name: "B640800005",
            fromDate: '20220104',
            toDate: '20220104',
        }
    },
    {
        id: 6,
        name: "ชม 70-1239",
        dateValue: "20220104",
        itemValue: {
            id: 6,
            name: "B640800006",
            fromDate: '20211231',
            toDate: '20220104',
        }
    },
    {
        id: 7,
        name: "ชม 70-1240",
        dateValue: "20220105",
        itemValue: {
            id: 7,
            name: "B640800007",
            fromDate: '20220101',
            toDate: '20220102',
        }
    },
    {
        id: 8,
        name: "ชม 70-1241",
        dateValue: "20220106",
        itemValue: {
            id: 8,
            name: "B640800008",
            fromDate: '20220101',
            toDate: '20220104',
        }
    },
    {
        id: 9,
        name: "ชม 70-1242",
        dateValue: "20220107",
        itemValue: {
            id: 9,
            name: "B640800009",
            fromDate: '20220103',
            toDate: '20220104',
        }
    },
    {
        id: 10,
        name: "ชม 70-1243",
        dateValue: "20211227",
        itemValue: {
            id: 10,
            name: "B640800010",
            fromDate: '20220102',
            toDate: '20220104',
        }
    }
]

const truckTypeNameList = ["6 ล้อคอก", "10 ล้อคอก", "10 ล้อทึบ"];

interface IParams {
    selectedDate: string;
    truckTypeId: string;
}

const TruckMatchingManage: React.FC<RouteComponentProps<IParams>> = (props) => {
    const [subject,setSubject] = useState([]);
    const [reservNoScrollRef, setReservNoScrollRef] = useState<HTMLElement>();
    const [reservDateScrollRef, setReservDateScrollRef] = useState<HTMLElement>();
    const [reservJobScrollRef, setReservJobScrollRef] = useState<HTMLElement>();
    const [calendarItems, setCalendarItem] = useState<Array<IHorizonTalCalendarData>>([]);
    const [selectedRegisNoIndex, setSelectedRegisNoIndex] = useState<number[]>([]);
    const [selectedReceiveNoIndex, setSelectedReceiveNoIndex] = useState<number>(-1);
    const [estimateActivateDate, setEstimateActivateDate] = useState<IActivateDateData[] | null>(null);
    const [selectedReceiveNoItemIndex, setSelectedReceiveNoItemIndex] = useState<number[]>([]);
    const [focustDate, setFocusDate] = useState<Date>();
    const [truckTypeName, setTruckTypeName] = useState<string>("");
    const [showUsageDate, setShowUsageDate] = useState<string>("");
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [isTruckListChanged, setIsTruckListChanged] = useState<boolean>(false);
    const [truckList, setTruckList] = useState<Truck[]>([]);
    const [isBookingListChanged, setIsBookingListChanged] = useState<boolean>(false);
    const [bookingList, setBookingList] = useState<BookingHead[]>([]);
    const [truckScheduleLoaded, setTruckScheduleLoaded] = useState<boolean>(false);
    const [calendaDate, setCalendarDate] = useState<{firstDate: Date, lastDate: Date}>({firstDate: new Date(), lastDate: new Date()});
    useEffect(() => {
        // setSubject(data);
        let data:any = subjectService.getSubject();
        let truckTypeId: number = Number(props.match.params.truckTypeId);
        let selectedDate: string = props.match.params.selectedDate;
        console.log({truckTypeId: truckTypeId, selectedDate: selectedDate});
        console.log(data);
        initTruckTypeName(truckTypeId);
        initTruckList(truckTypeId);
        initBookingList(truckTypeId, selectedDate);
        setShowUsageDate(DateFormat.convert2ShowDateFormat(selectedDate));
        setSubject(data);
        setFocusDate(new Date());
        // createCalendarItem();
    },[]);

    useEffect(() => {
        // console.log("useEffect selectedReceiveNoIndex: ", selectedReceiveNoIndex);
        if(selectedReceiveNoIndex > -1){
            let selectedItem = bookingList[selectedReceiveNoIndex];
            console.log("useEffect selectedReceiveNoIndex: ", selectedItem);
            setEstimateActivateDate([{
                fromDate: selectedItem.startDate,
                toDate: selectedItem.endDate,
                diffDate: DateFormat.getDiffDate(DateFormat.convertDBDateStr2Date(selectedItem.startDate), DateFormat.convertDBDateStr2Date(selectedItem.endDate)),
                dataValue: selectedItem
            }]);
            // console.log("useEffect selectedReceiveNoIndex: ", [{
            //     fromDate: selectedItem.startDate,
            //     toDate: selectedItem.endDate,
            //     diffDate: DateFormat.getDiffDate(DateFormat.convertDBDateStr2Date(selectedItem.startDate), DateFormat.convertDBDateStr2Date(selectedItem.endDate))
            // }]);
        }
    }, [selectedReceiveNoIndex]);

    useEffect(() => {
        if(isSelectAll){
            let newSelectedRegisNoIndex: any[] = [];
            let newSelectedReceiveNoItemIndex: any[] = [];
            for(let i = 0; i < items3.length; i++){
                newSelectedRegisNoIndex.push(i);
                newSelectedReceiveNoItemIndex.push(i);
            }
            setSelectedRegisNoIndex(newSelectedRegisNoIndex);
            setSelectedReceiveNoItemIndex(newSelectedReceiveNoItemIndex);
        }
    }, [isSelectAll])

    useEffect(() => {
        if(truckList && truckList.length > 0){
            let firstDate = new Date(calendaDate.firstDate.getTime());
            firstDate.setDate(firstDate.getDate() - 5)
            let lastDate = new Date(calendaDate.lastDate.getTime());
            lastDate.setDate(firstDate.getDate() + 15)
            loadTruckSchedule(DateFormat.convertDate2DBDateFormat(firstDate), DateFormat.convertDate2DBDateFormat(lastDate));
        }
    }, [calendaDate]);

    useEffect(() => {
        if(selectedRegisNoIndex.length == 0){
            setIsSelectAll(false);
        }
    }, [selectedRegisNoIndex]);

    useEffect(() => {
        loadTruckSchedule(DateFormat.convertDate2DBDateFormat(calendaDate.firstDate), DateFormat.convertDate2DBDateFormat(calendaDate.lastDate));
        setIsTruckListChanged(true);
    }, [truckList]);
    useEffect(() => {
        setIsBookingListChanged(true);
    }, [bookingList]);
    useEffect(() => {
        if(truckScheduleLoaded){
            createCalendarItem();
            setTruckScheduleLoaded(false);
        }
    }, [truckScheduleLoaded])

    const initTruckTypeName = (truckTypeId: number) => {
        truckMatchService.getTruckTypeById(truckTypeId).then((res) => {
            if(res && res.data){
                setTruckTypeName(res.data.typeName);
            }
        });
    }

    const initTruckList = (truckTypeId: number) => {
        truckMatchService.getTruckByTypeId(truckTypeId).then((res) => {
            if(res && res.data){
                let truckListData: Truck[] = res.data;
                console.log("truckListData: ", truckListData);
                setIsTruckListChanged(false);
                setTruckList(truckListData);
            }
        });
    }

    const initBookingList = (truckTypeId: number, bookingDate: string) => {
        truckMatchService.getBookingListByBookingDateTruckTypeId(bookingDate, truckTypeId).then((res) => {
            if(res && res.data){
                let bookingList: BookingHead[] = res.data;
                setIsBookingListChanged(false);
                setBookingList(bookingList);
            }
        });
    }

    const loadTruckSchedule = async (fromDate: string, endDate: string) => {
        for(let i = 0; i < truckList.length; i++){
            let truck:Truck = truckList[i];
            console.log("loadTruckSchedule: ", {id: truck.id, fromDate: fromDate, lastDate: endDate});
            await truckMatchService.getBookingMapByTruck(truck.id, fromDate, endDate).then((res) => {
                if(res && res.data){
                    truck.schdule = res.data;
                    console.log("loadTruckSchedule: ", truck.schdule);
                }
            });
        }
        setTruckScheduleLoaded(true);
    }

    const updateDate = (firstDate: Date, lastDate: Date) => {
        if(!firstDate || !lastDate){
            return;
        }
        if(calendaDate && calendaDate.firstDate && calendaDate.lastDate){
            if(DateFormat.getDiffDate(calendaDate.firstDate, firstDate) != 0 || DateFormat.getDiffDate(calendaDate.lastDate, lastDate)){
                setCalendarDate({firstDate: firstDate, lastDate: lastDate});
            }
        }else{
            setCalendarDate({firstDate: firstDate, lastDate: lastDate});
        }
    }

    const createCalendarItem = () => {
        let items: Array<IHorizonTalCalendarData> = [];
        for(let i = 0; i < truckList.length; i++){
            let truck:Truck = truckList[i];
            let activateDateList = [];
            if(truck.schdule){
                for(let j = 0; j < truck.schdule.length; j++){
                    let schedule: BookingHead = truck.schdule[j];
                    let activateDate: IActivateDateData = {
                        fromDate: schedule.startDate,
                        toDate: schedule.endDate,
                        diffDate: DateFormat.getDiffDate(DateFormat.convertDBDateStr2Date(schedule.startDate), DateFormat.convertDBDateStr2Date(schedule.endDate)),
                        dataValue: schedule
                    }
                    activateDateList.push(activateDate);
                    // let activateDate2: IActivateDateData = {
                    //     fromDate: '20220101',
                    //     toDate: '20220103',
                    //     diffDate: DateFormat.getDiffDate(DateFormat.convertDBDateStr2Date('20211231'), DateFormat.convertDBDateStr2Date('20220102'))
                    // }
                    
                }
            }
            let item: IHorizonTalCalendarData = {
                id: truck.id,
                activateDate: activateDateList,
                itemValue: truck
            }
            items.push(item);
        }
        setCalendarItem(items);
    }

    const history = useHistory();
    const { Content } = Layout;

    const onChange = () => {

    }
    const onClickItem = () => {

    }
    const onClickThumb = () =>{

    }
    const onCarouselScroll = (scrollTop: number) => {
        console.log("onCarouselScroll", scrollTop);
        if(reservDateScrollRef){
            // console.log("reservDateScrollRef.scrollTop: ", reservDateScrollRef.scrollTop);
            reservDateScrollRef.scrollTop = scrollTop;
        }
    }
    const onCalendarScroll = (scrollTop: number) => {
        console.log("onCalendarScroll", scrollTop);
        if(reservNoScrollRef){
            // console.log("reservNoScrollRef.scrollTop: ", reservNoScrollRef.scrollTop);
            reservNoScrollRef.scrollTop = scrollTop;
        }
    }
    const renderBody1 = (item: any, index: number) => {
        return (
            <GroupContainer header={item.name}>
                <div>6 ล้อคอก : 30</div>
                <div>10 ล้อคอก : 15</div>
                <div>10 ล้อทึบ : 20</div>
            </GroupContainer>
        );
    }
    const hasEqualIndexList = (selectedIndexList: number[], currentIndex: number) => {
        let findList = selectedIndexList.find((selectedIndex: number) => {
            return selectedIndex == currentIndex;
        });
        return findList != undefined && findList != null;
    }
    const body2Style = (item: any, index: number) => {
        return selectedRegisNoIndex != null && hasEqualIndexList(selectedRegisNoIndex, index) ? { backgroundColor: '#268DDD', color: '#FAFAFA' } : null;
    }
    const renderBody2 = (item: Truck, index: number) => {
        return (
            <GroupContainer header={item.registId} containerStyle={body2Style(item, index)} tooltip={`${item.partnerName}(${item.driverName})`}>
            </GroupContainer>
        );
    }
    const body3Style = (item: any, index: number) => {
        let bgColor = null;
        if(item.status != "00" && item.status != "01"){
            bgColor = "#48B800"; //: item.status == "DONE" ? "rgba(72, 184, 4, 1)" : null;
        }else{
            bgColor = selectedReceiveNoIndex != null && selectedReceiveNoIndex == index ? "#268DDD" : null;
        }
        return bgColor != null ? { backgroundColor: bgColor, color: '#FAFAFA', cursor: 'pointer' } : {cursor: 'pointer'};
    }
    const renderBody3 = (item: BookingHead, index: number) => {
        let fromShowDate = DateFormat.convert2ShowDateFormat(item.startDate);
        let toShowDate = DateFormat.convert2ShowDateFormat(item.endDate);
        let showDate = `${fromShowDate} - ${toShowDate}`
        let header: any = item.bookNo;
        if(item.status == "01"){
        // if(true){
            header = (
                <div style={{display: 'flex', }}>
                    {item.bookNo}
                    <img style={{width: '16px', height: '16px', marginLeft: '5px'}} src={"/assets/icons/notification-black.png"}></img>
                </div>
            );
        }
        return (
            <GroupContainer 
                header={header} 
                containerStyle={body3Style(item, index)} 
                tooltip={showDate}
            >
            </GroupContainer>
        );
    }
    const colorList = ['', '', '', '', '', '', ''];

    const renderDateBody = (date: Date, item: IHorizonTalCalendarData, rowIndex: number, index: number, dataValue: BookingHead) => {
        let dateValueStr = DateFormat.convertDate2DBDateFormat(date);
        let itemValue = item.activateDate;
        // console.log("itemValue: ", itemValue);
        // console.log("itemValue.fromDate: ", itemValue.fromDate);
        // console.log("itemValue.toDate: ", itemValue.toDate);
        // let fromDate: Date = DateFormat.convertDBDateStr2Date(itemValue.fromDate);
        // let toDate: Date = DateFormat.convertDBDateStr2Date(itemValue.toDate);
        // if(date >= fromDate && date <= toDate){
            return (
                <div style={{display: 'flex', flex: 3, height: '100%', borderRadius: '0px', border: 'rgba(2, 138, 4, 1) 2px solid', color: 'black', backgroundColor: "rgba(72, 184, 4, 0.3)", alignItems: 'center', justifyContent: 'center', borderWidth: '2px', zIndex: index}}>
                    {dataValue.bookNo}
                </div>
            );
        // }else{
        //     return (
        //         <div>
                    
        //         </div>
        //     );
        // }
    }

    const [hasOverlapDic, setHasOverlapDic] = useState<any>({});
    const addHasOverlapDic = (key: string, value: any) => {
        let hasOverlapDicTmp = hasOverlapDic;
        hasOverlapDicTmp[key] = value;
        setHasOverlapDic(hasOverlapDicTmp);
    }
    const removeHasOverlapDic = (key: string) => {
        let hasOverlapDicTmp = hasOverlapDic;
        delete hasOverlapDicTmp[key];
        setHasOverlapDic(hasOverlapDicTmp);
    }
    const clearHasOverlapDic = () => {
        setHasOverlapDic({});
    }
    const renderCheckedIcon = () => {
        let size = 18;
        if(isSelectAll){
            return (
                <img src='/assets/icons/check.png' style={{width: size, height: size, marginRight: 5}}></img>
            );
        }else{
            return (
                <img src='/assets/icons/circle.png' style={{width: size, height: size, marginRight: 5}}></img>
            );
        }
    }
    const [countCanSelect, setCountCanSelect] = useState<number>(0);
    const renderEstimateDateBody = (date: Date, item: IHorizonTalCalendarData, rowIndex: number, colIndex: number, hasOverlap: boolean, dataValue: any) => {
        let dateValueStr = DateFormat.convertDate2DBDateFormat(date);
        let itemValue = item.itemValue;
        let lineColor = hasOverlap ? "rgba(153, 0, 0, 1)" : "rgba(0, 56, 153, 1)";
        let bgColor = hasOverlap ? "rgba(255, 91, 91, 0.3)" : "rgba(91, 151, 255, 0.3)";
        let dateList = [];
        let hasSelected = hasEqualIndexList(selectedReceiveNoItemIndex, rowIndex);
        // let newCountValue = hasOverlap ? countCanSelect + 0 : countCanSelect + 1;
        // setCountCanSelect(newCountValue);
        if(hasOverlap && hasSelected){
            let newSelectedReceiveNoItemIndex = JSON.parse(JSON.stringify(selectedReceiveNoItemIndex));
            let newSelectedRegisNoIndex = JSON.parse(JSON.stringify(selectedRegisNoIndex));
            newSelectedReceiveNoItemIndex.splice(newSelectedReceiveNoItemIndex.indexOf(rowIndex), 1);
            newSelectedRegisNoIndex.splice(newSelectedRegisNoIndex.indexOf(rowIndex), 1);
            setSelectedReceiveNoItemIndex(newSelectedReceiveNoItemIndex);
            setSelectedRegisNoIndex(newSelectedRegisNoIndex);
            return;
        }
        let lineType = hasSelected ? "4px solid" : "2px dashed";
        return (
            <div 
                style={{display: 'flex', flex: 3, height: '100%', borderRadius: '0px', border: `${lineColor} ${lineType}`, color: 'black', backgroundColor: bgColor, alignItems: 'center', justifyContent: 'center', zIndex: colIndex}} 
                onClick={() => {
                    if(hasOverlap){
                        return;
                    }
                    let newSelectedReceiveNoItemIndex = JSON.parse(JSON.stringify(selectedReceiveNoItemIndex));
                    let newSelectedRegisNoIndex = JSON.parse(JSON.stringify(selectedRegisNoIndex));
                    if(hasEqualIndexList(selectedReceiveNoItemIndex, rowIndex)){
                        newSelectedReceiveNoItemIndex.splice(newSelectedReceiveNoItemIndex.indexOf(rowIndex), 1);
                        newSelectedRegisNoIndex.splice(newSelectedRegisNoIndex.indexOf(rowIndex), 1);
                        setSelectedReceiveNoItemIndex(newSelectedReceiveNoItemIndex);
                        setSelectedRegisNoIndex(newSelectedRegisNoIndex);
                        setIsSelectAll(false);
                    }else{
                        newSelectedReceiveNoItemIndex.push(rowIndex);
                        newSelectedRegisNoIndex.push(rowIndex);
                        setSelectedReceiveNoItemIndex(newSelectedReceiveNoItemIndex);
                        setSelectedRegisNoIndex(newSelectedRegisNoIndex);
                    }
                }}
            >
                {selectedReceiveNoIndex == null ? '' : bookingList[selectedReceiveNoIndex].bookNo}
            </div>
        );
    }

    const renderTruckCarousal = () => {
        if(isTruckListChanged){
            if(truckList == null || truckList.length < 1){
                return (
                    <div style={{flex: 1,}} className={styles["columnBody"]}>
                        <GroupContainer header={truckTypeName}>
                            ไม่พบข้อมูล
                        </GroupContainer>
                    </div>
                );
            }
            return (
                <div style={{flex: 1,}} className={styles["columnBody"]}>
                    <GroupContainer header={truckTypeName}>
                        <div className={styles["rowBody"]} style={{alignItems: 'center'}}>
                            <Button 
                                size="small"
                                type="primary"
                                onClick={() => {
                                    setIsSelectAll(true);
                                    // setFocusDate(DateFormat.convertDBDateStr2Date(items2[selectedReceiveNoIndex].fromDate))
                                    // if(isSelectAll){
                                    //     setSelectedRegisNoIndex([]);
                                    //     setSelectedReceiveNoItemIndex([]);
                                    // }
                                }}
                            >
                                เลือกทั้งหมด 
                            </Button>
                        </div>
                        <Carousel 
                            contentScrollRef={(ref: HTMLElement) => {setReservNoScrollRef(ref)}}
                            contentScrollLink={reservDateScrollRef}
                            items={truckList} 
                            showItem={7} 
                            renderBody={renderBody2}
                            onItemClicked={(itemData: any, clickedIndex: number) => {
                                if(selectedReceiveNoIndex < 0){
                                    return;
                                }
                                let newSelectedReceiveNoItemIndex = JSON.parse(JSON.stringify(selectedReceiveNoItemIndex));
                                let newSelectedRegisNoIndex = JSON.parse(JSON.stringify(selectedRegisNoIndex));
                                setFocusDate(DateFormat.convertDBDateStr2Date(bookingList[selectedReceiveNoIndex].startDate));
                                if(hasEqualIndexList(selectedRegisNoIndex, clickedIndex)){
                                    newSelectedReceiveNoItemIndex.splice(newSelectedReceiveNoItemIndex.indexOf(clickedIndex), 1);
                                    newSelectedRegisNoIndex.splice(newSelectedRegisNoIndex.indexOf(clickedIndex), 1);
                                    setSelectedReceiveNoItemIndex(newSelectedReceiveNoItemIndex);
                                    setSelectedRegisNoIndex(newSelectedRegisNoIndex);
                                    setIsSelectAll(false);
                                }else{
                                    newSelectedReceiveNoItemIndex.push(clickedIndex);
                                    newSelectedRegisNoIndex.push(clickedIndex);
                                    setSelectedReceiveNoItemIndex(newSelectedReceiveNoItemIndex);
                                    setSelectedRegisNoIndex(newSelectedRegisNoIndex);
                                }
                            }}
                            // onContentScroll={onCarouselScroll}
                        >
                        </Carousel>
                    </GroupContainer>
                </div>
            );
        }
    }
    const renderBookingTruck = () => {
        if(isBookingListChanged){
            if(bookingList == null || bookingList.length < 1){
                return (
                    <div style={{flex: 1, margin: "5px 25px 5px 5px",}} className={`${styles["columnBody"]} ${styles["radiusBody"]}`}>
                        <GroupContainer header={`วันที่จอง ${showUsageDate}`}>
                            ไม่พบข้อมูล
                        </GroupContainer>
                    </div>
                );
            }
            return (
                <div style={{flex: 1, margin: "5px 25px 5px 5px",}} className={`${styles["columnBody"]} ${styles["radiusBody"]}`}>
                    <GroupContainer header={`วันที่จอง ${showUsageDate}`}>
                        <div className={styles["rowBody"]} style={{alignItems: 'center'}}>
                            เลขที่การจอง
                        </div>
                        <Carousel 
                            contentScrollRef={(ref: HTMLElement) => {setReservJobScrollRef(ref)}}
                            items={bookingList} 
                            renderBody={renderBody3} 
                            showItem={7} 
                            onItemClicked={(itemData: any, clickedIndex: number) => {
                                setFocusDate(DateFormat.convertDBDateStr2Date(bookingList[clickedIndex].startDate));
                                if(itemData.status != "00" || selectedReceiveNoIndex == clickedIndex){
                                    return;
                                }
                                setEstimateActivateDate([]);
                                setSelectedReceiveNoIndex(clickedIndex);
                                setSelectedReceiveNoItemIndex([]);
                                setSelectedRegisNoIndex([]);
                                setIsSelectAll(false);
                            }}
                        >
                        </Carousel>
                    </GroupContainer>
                </div>
            );
        }
    }

    const getCommitButtonText = () => {
        if(selectedRegisNoIndex.length > 1){
            return "บันทึก"
        }else{
            return "ส่งข้อมูล"
        }
    }
    const isDisableCommitButton = () => {
        return selectedRegisNoIndex.length == 0 || selectedReceiveNoIndex == -1
    }

    const onCommitClicked = async () => {
        let selectedTruckList: Truck[] = truckList.filter((value, index) => {
            return selectedRegisNoIndex.findIndex((regisNoIndex) => {return regisNoIndex == index}) > -1;
        });
        console.log("selectedTruckList: ", selectedTruckList);
        let selectedBooking: BookingHead = bookingList[selectedReceiveNoIndex];
        selectedBooking.truckTypeName = truckTypeName;
        console.log("commit data: ", {selectedTruckList: selectedTruckList, selectedBooking: selectedBooking});
        if(selectedRegisNoIndex.length > 1){
            history.push({pathname: "/app/matching/manage/sendRequest", state: {selectedTruckList: selectedTruckList, selectedBooking: selectedBooking}})
        }else{
            for(let i = 0; i < selectedTruckList.length; i++){
                selectedTruckList[i].truckId = selectedTruckList[i].id;
            }
            selectedBooking.truckList = selectedTruckList;
            console.log("selectedBooking: ", selectedBooking);
            console.log(selectedBooking);
            await truckMatchService.addTruckBooking(selectedBooking).then((res) => {
                if(res){
                    if(res.data){
                        console.log("addTruckBooking success: ", res.data);
                        history.replace("/app/matching/list");
                    }else{
                        console.log("addTruckBooking fail: ", res.data);
                        alert("ส่งข้อมูลไม่สำเร็จ");
                    }
                }
            });
        }
    }

    return (
        <div>
            <PageHeader title="จับคู่รถ">
                <Button
                    size="small"
                    type="default"
                    // icon={<PlusOutlined />}
                    onClick={() => { history.replace("/app/matching/list") }} 
                    >ยกเลิก
                </Button>
                &nbsp;
                <Button
                    size="small"
                    type="primary"
                    disabled={isDisableCommitButton()}
                    // icon={<PlusOutlined />}
                    onClick={onCommitClicked} 
                    >{getCommitButtonText()}
                </Button>


            </PageHeader>
            <Content className="app-page-content">
                <div className={styles["contentBody"]} style={{minHeight: '300px'}}>
                    <div className={styles["rowBody"]}>
                        <div style={{flex: 5, margin: "5px 5px 5px 0px",}} className={`${styles["rowBody"]} ${styles["radiusBody"]}`}>
                            {renderTruckCarousal()}
                            <div style={{flex: 4}} className={styles["columnBody"]}>
                                <GroupContainer header={`วันที่ใช้งาน`}>
                                    <div className={styles["rowBody"]} style={{margin: 10,height: 29, alignItems: 'center'}}>
                                    </div>
                                    <HorizontalCalendar 
                                        contentScrollRef={(ref: HTMLElement) => {setReservDateScrollRef(ref)}}
                                        contentScrollLink={reservNoScrollRef}
                                        items={calendarItems} 
                                        renderCellBody={renderDateBody}
                                        showDate={7} 
                                        focusDate={focustDate}
                                        showItem={7}
                                        estimateActivateDate={estimateActivateDate == null ? undefined : estimateActivateDate}
                                        renderEstimateCellBody={renderEstimateDateBody}
                                        updateDate={updateDate}
                                        // onContentScroll={onCalendarScroll}
                                        // correctColorCode="red"
                                    >
                                    </HorizontalCalendar>
                                </GroupContainer>
                            </div>
                        </div>
                        {renderBookingTruck()}
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default TruckMatchingManage;
