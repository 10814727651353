const subjectData = [
  { id: 1, name: "Math", unit: 3, level: "Senior high school" },
  { id: 2, name: "Science", unit: 2, level: "Junior high school" },
  { id: 3, name: "Biology", unit: 1, level: "High school certificate" },
  { id: 4, name: "Chemist", unit: 3, level: "Primary school" },
  { id: 5, name: "Physical education", unit: 1, level: "High school" },
];

const getSubject = () => {
  // return http.get(url);
  return subjectData;
};

const getSubjectById = (id: number) => {
  // return http.get(`${url}/${id}`);
   let x = subjectData.filter((item) => item.id === id);
   return x[0];
};

const updateSubject = (Subject: any) => {
  // return http.put(url + "/" + Teacher.id, Teacher);
  const data = getSubjectById(Subject.id);
//   const data = datas;
  data.id = Subject.id;
  data.name = Subject.name;
  data.unit = Subject.unit;
  data.level = Subject.level;
  return data;
};

const addSubject = (Subject: any) => {
    console.log(Subject)
  const newSubject = { ...Subject };
  newSubject.id = subjectData.length+1;
  subjectData.push(newSubject);
  console.log(subjectData)
  return newSubject;
};

const deleteSubject = (id: number) => {
    let index = subjectData.findIndex((item) => item.id === id);
    if(index !== -1) subjectData.splice(index, 1);
};


export default {
  getSubject,
  getSubjectById,
  updateSubject,
  addSubject,
  deleteSubject
};
