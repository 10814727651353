import { Button, Steps } from 'antd'
import { CloseCircleOutlined } from "@ant-design/icons";
import { RouteComponentProps, useHistory } from 'react-router';
import React, { useState } from 'react'
// import First from "./First";
import BusinessDataInfo from './BusinessDataInfo';
import BusinessFileInfo from './BusinessFileInfo';
import BusinessUserInfo from './BusinessUserInfo';


const { Step } = Steps;

interface IProps {
    id: number;
    userId: number;
    username: string;
    station: string;
}


const BusinessForm: React.FC<IProps> = (props) => {

    const { id, userId = 99, username = 'sittiponn', station = '11' } = props;

    console.log("BusinessForm 2 >>>>>> " + id,)

    const history = useHistory();
    const [current, setCurrent] = useState<any>(0);



    const onChange = () => {
        setCurrent(current);
    };

    const handleClickNext = () => {
        setCurrent(current + 1);
    };

    const handleClickPrev = () => {
        setCurrent(current - 1);
    };

    const paymentListContent = () => {

        if (true) {
            return (
                <div>
                    {/* <StepListOrder
            onNextStep={() => {
              next();
            }}
            onPrevStep={() => {
              prev();
            }}
            onAddBill={handleAddOrder}
            billHeads={billHeads}
          />  */}
                </div>
            );
        }

        // if (paymentDoc === "order") {
        //     return (
        //         <div>
        //             {/* <StepListOrder
        //     onNextStep={() => {
        //       next();
        //     }}
        //     onPrevStep={() => {
        //       prev();
        //     }}
        //     onAddBill={handleAddOrder}
        //     billHeads={billHeads}
        //   /> */}
        //         </div>
        //     );
        // } else if (paymentDoc === "smc") {
        //     return (
        //         <div>
        //             {/* <StepListSMC
        //     onNextStep={() => {
        //       next();
        //     }}
        //     onPrevStep={() => {
        //       prev();
        //     }}
        //   /> */}
        //         </div>
        //     );
        // } else if (paymentDoc === "statement") {
        //     return (
        //         <div>
        //             {/* <StepListStatement
        //     onNextStep={() => {
        //       next();
        //     }}
        //     onPrevStep={() => {
        //       prev();
        //     }}
        //   /> */}
        //         </div>
        //     );
        // }
    };

    const steps = [

        {
            title: "ข้อมูล",
            content: <BusinessDataInfo
                // id={id}
                // userId={userId}
                // username={username}
                // station={station}
                isSave={() => {
                    console.log("onNextStep");
                    handleClickNext();
                }} />
        },
        {
            title: "เอกสารแนบ",
            content: <BusinessFileInfo
                id={id}
                userId={userId}
                username={username}
                station={station}
                onNextStep={() => {
                    console.log("onNextStep");
                    handleClickNext();
                }} />
        },
        {
            title: "บัญชีผู้ใช้",
            content: <BusinessUserInfo
                id={id}
                userId={userId}
                username={username}
                station={station}
                onNextStep={() => {
                    console.log("onNextStep");
                    handleClickNext();
                }} />
        },

    ];

    return (
        <>
            <Button
                size="small"
                icon={<CloseCircleOutlined />}
                onClick={() => history.goBack()}
                shape="circle"
                type="primary"
                danger
            ></Button>
            <Steps
                type="navigation"
                current={current}
                className="steps-content"
                onChange={onChange}

            >
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} ></Step>
                ))}
            </Steps>
            <div
                style={{ paddingLeft: "30px", paddingTop: "25px", }}
            // border: "2px solid red ",
            >{steps[current].content}</div>

            {/* {current < 4 && ( */}
            <div style={{ textAlign: "center" }}>
                <Button onClick={handleClickPrev}>Prev step</Button>
                <Button onClick={handleClickNext}>Next step</Button>
            </div>

            {/* )} */}
        </>
    )
}

export default BusinessForm