import React, { useState, useEffect } from "react";
import { isConstructorDeclaration, transform } from "typescript";
import dateformat from "../../utils/dateUtils";
import DateFormat from '../../utils/dateUtils';
import mStyles from './css/HorizontalCalendar.module.css';


type justifyType = "center" | "start" | "end" | "between" | "around";
type alignItemsType = "center" | "start" | "end";
type flexDirectionType = "row" | "column";

interface IProps {
  className?: string;
  alignItems?: alignItemsType;
  justifyContent?: justifyType;
  children?: React.ReactNode;
  headerClassName?: string;
  items: IHorizonTalCalendarData[];
  renderCellBody?: (date: Date, item: IHorizonTalCalendarData, rowIndex: number, colIndex: number, dataValue: any) => any;
  showItem?: number;
  focusDate?: Date; 
  showDate?: number;
  correctColorCode?: string;
  onContentScroll?: Function;
  contentScrollRef?: Function;
  contentScrollLink?: HTMLElement;
  estimateActivateDate?: IActivateDateData[];
  renderEstimateCellBody?: (date: Date, item: IHorizonTalCalendarData, rowIndex: number, colIndex: number, hasOverlap: boolean, dataValue: any) => any;
  updateDate?: (firstDate: Date, lastDate: Date) => void;
}

interface IYearData {
    yearLabel: string,
    countDate: number
}

export interface IHorizonTalCalendarData {
    id: number,
    activateDate: IActivateDateData[],
    itemValue: any
}

export interface IActivateDateData {
    fromDate: string,
    toDate: string,
    diffDate: number,
    dataValue: any
}

const HorizontalCalendar: React.FC<IProps> = ({
  className,
  alignItems = "center",
  justifyContent = "start",
  children,
  items,
  renderCellBody,
  showItem = 3,
  focusDate = new Date(),
  showDate = 7,
  correctColorCode = '#48B804',
  onContentScroll,
  contentScrollRef,
  contentScrollLink,
  estimateActivateDate = null,
  renderEstimateCellBody,
  updateDate
}) => {
    const [mainContainer, setMainContainer] = useState<HTMLElement | undefined>();
    const [upButton, setUpButton] = useState<HTMLElement | undefined>();
    const [downButton, setDownButton] = useState<HTMLElement | undefined>();
    const [childBody, setChildBody] = useState<HTMLElement | undefined>();
    const [contentBody, setContentBody] = useState<HTMLElement | undefined>();
    const [parentContentBody, setParentContentBody] = useState<HTMLElement | undefined>();
    const [topItemIndex, setTopItemIndex] = useState<number>(0);
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
    const [currScroll, setCurrScroll] = useState<number>(0);
    const [dateList, setDateList] = useState<Array<Date>>([]);
    const [currDateList, setCurrDateList] = useState<Array<Date>>([]);
    const [datePercWidth, setDatePercWidth] = useState<number>(0);
    const [currentFocusDate, setCurrentFocusDate] = useState<Date>(new Date());
    const [yearList, setYearList] = useState<Array<IYearData>>([]);
    const [maxContentHeight, setMaxContentHeight] = useState<number>(0);
    const [estimateActivateDateState, setEstimateActivateDateState] = useState<IActivateDateData[] | null>();
    const [currentItems, setCurrentItems] = useState<IHorizonTalCalendarData[]>([]);
    const [isCurrentItemsChanged, setIsCurrentItemsChanged] = useState<boolean>(false);

    useEffect(() => {
        let contentBodyChildCount = contentBody ? contentBody.childElementCount : 0;
        let maxHeight = 0;
        for(let i = 0; i < contentBodyChildCount && i < showItem; i++){
            let currChildBounding = contentBody?.children.item(i)?.getBoundingClientRect();
            maxHeight += currChildBounding ? currChildBounding.height : 0;
        }
        
        setMaxContentHeight(maxHeight);
        if(childBody){
            childBody.style.height = maxHeight + "px";
        }
        if(parentContentBody){
            parentContentBody.style.height = maxHeight + "px";
        }
        if(contentScrollRef){
            contentScrollRef(parentContentBody);
        }
    }, [contentBody]);

    useEffect(() => {
        if(dateList){
            if(updateDate){
                // console.log("dateListLog", dateList);
                updateDate(dateList[0], dateList[dateList.length - 1]);
            }
        }
    }, [dateList]);

    useEffect(() => {
        // console.log("useEffect estimateActivateDate");
        setEstimateActivateDateState(estimateActivateDate);
    },[estimateActivateDate]);

    useEffect(() => {
        setCurrentFocusDate(focusDate);
    }, [focusDate])

    useEffect(() => {
        createDateList(currentFocusDate);
    }, [currentFocusDate]);
    useEffect(() => {
        // console.log("items changed");
        setIsCurrentItemsChanged(false);
        setCurrentItems(items);
    }, [items]);
    useEffect(() => {
        setIsCurrentItemsChanged(true);
    }, [currentItems]);

    const createDateList = (localFocusDate: Date) => {
        // console.log("createDateList: ", localFocusDate);
        let tmpDateList = [];
        let halfDate = showDate % 2 == 0 ? showDate / 2 : (showDate - 1) / 2;
        let beforeDate = showDate % 2 == 0 ? halfDate - 1 : halfDate;
        let afterDate = halfDate;
        setDatePercWidth(100 / showDate);
        for(let i = beforeDate; i > 0; i--){
            let beforeDate = new Date(localFocusDate.getTime());
            beforeDate.setDate(beforeDate.getDate() - i);
            tmpDateList.push(beforeDate);
        }
        tmpDateList.push(localFocusDate);
        for(let i = 1; i <= afterDate; i++){
            let afterDate = new Date(localFocusDate.getTime());
            afterDate.setDate(afterDate.getDate() + i);
            tmpDateList.push(afterDate);
        }
        let yearDict: {[name: string]: number} = {};
        let tmpYearList: Array<IYearData> = [];
        for(let i = 0; i < tmpDateList.length; i++){
            let key:any = "" + tmpDateList[i].getFullYear();
            if(key in yearDict){
                yearDict[key]++;
            }else{
                yearDict[key] = 1;
            }
        }
        // console.log("yearDict: ", yearDict);
        for(let key in yearDict){
            let yearData: IYearData = {yearLabel: key, countDate: yearDict[key]}
            tmpYearList.push(yearData);
        }
        // console.log("tmpYearList: ", tmpYearList);
        
        setDateList(tmpDateList);
        setYearList(tmpYearList);
    }

    const onLeftClick = () => {
        // console.log("onLeftClick");
        let newFocusDate = new Date(currentFocusDate.getTime());
        newFocusDate.setDate(newFocusDate.getDate() - 1);
        setCurrentFocusDate(newFocusDate);
    }
    const onRightClick = () => {
        // console.log("onRightClick");
        let newFocusDate = new Date(currentFocusDate.getTime());
        newFocusDate.setDate(newFocusDate.getDate() + 1);
        setCurrentFocusDate(newFocusDate);
    }
    const getColor = (dateValue: Date, itemdateValue: string) => {
        let dateValueStr = DateFormat.convertDate2DBDateFormat(dateValue);
        if(dateValueStr == itemdateValue){
            return correctColorCode
        }else{
            return "#FFFFFF"
        }
    }
    const [handleScroll, setHandleScroll] = useState<any>(null);
    const onContentScrollFn = () => {
        // if(onContentScroll){
            if(handleScroll != null){
                clearTimeout(handleScroll);
                setHandleScroll(null);
            }
            setHandleScroll(setTimeout(() => {
                if(parentContentBody){
                    if(onContentScroll){
                        onContentScroll(parentContentBody.scrollTop);
                    }else{
                        onMyContentScroll(parentContentBody.scrollTop);
                    }
                }
            }, 50))
        // }
    }
    const onMyContentScroll = (scrollTop: number) => {
        // console.log("onMyContentScroll: ", scrollTop);
        if(contentScrollLink){
            // console.log("contentScrollLink.scrollTop: ", contentScrollLink.scrollTop);
            contentScrollLink.scrollTop = scrollTop;
        }
    }
    const isEqFromDate = (itemActiveDateList: IActivateDateData[], currentDate: Date, firstDateInCalendar: Date) => {
        let resultSet = [];
        for(let i = 0; i < itemActiveDateList.length; i++){
            let itemActiveDate = itemActiveDateList[i];
            let itemActiveDateRes: IActivateDateData = {
                fromDate: itemActiveDate.fromDate,
                toDate: itemActiveDate.toDate,
                diffDate: itemActiveDate.diffDate,
                dataValue: itemActiveDate.dataValue
            }
            let fromDate = DateFormat.convertDBDateStr2Date(itemActiveDate.fromDate);
            let toDate = DateFormat.convertDBDateStr2Date(itemActiveDate.toDate);
            if(firstDateInCalendar > fromDate && firstDateInCalendar <= toDate){
                fromDate = firstDateInCalendar;
                itemActiveDateRes.fromDate = DateFormat.convertDate2DBDateFormat(fromDate);
                itemActiveDateRes.diffDate = DateFormat.getDiffDate(
                    DateFormat.convertDBDateStr2Date(itemActiveDateRes.fromDate)
                    , DateFormat.convertDBDateStr2Date(itemActiveDateRes.toDate));
            }
            if((currentDate.getDate() == fromDate.getDate()) 
                && (currentDate.getMonth() == fromDate.getMonth())
                && (currentDate.getFullYear() == fromDate.getFullYear())
            ){
                resultSet.push(itemActiveDateRes);
                // return itemActiveDateRes;
            }
        }
        return resultSet;
    }
    const isBetweenActivateDate = (itemActiveDateList: IActivateDateData[], currentDate: Date) => {
        for(let i = 0; i < itemActiveDateList.length; i++){
            let itemActiveDate = itemActiveDateList[i];
            let fromDate: Date   = DateFormat.convertDBDateStr2Date(itemActiveDate.fromDate);
            let toDate: Date = DateFormat.convertDBDateStr2Date(itemActiveDate.toDate);
            if(currentDate >= fromDate && currentDate <= toDate){
                return true;
            }
        }
        return false;
    }
    const convertDateDataList = (dateList: IActivateDateData[]) => {
        let result: {fromDate: Date, toDate: Date}[] = []
        dateList.forEach((date, index) => {
            result.push(convertDateData(date));
        });
        return result;
    }
    const convertDateData = (date: IActivateDateData) => {
        return {fromDate: DateFormat.convertDBDateStr2Date(date.fromDate), toDate: DateFormat.convertDBDateStr2Date(date.toDate)}
    }
    const renderCalenderBody = () => {
        if(isCurrentItemsChanged){
            return(
                <div
                    ref={(el: HTMLTableSectionElement) => {setParentContentBody(el);}} 
                    onScroll={onContentScrollFn}
                    style={{overflowY: 'auto', scrollBehavior: 'smooth', scrollSnapType: 'y mandatory', }}
                >
                    <table style={{ width: "100%", tableLayout: 'fixed'}}>
                        <tbody
                            ref={(el: HTMLTableSectionElement) => {setContentBody(el);}} 
                            className={mStyles['mTbody']}
                        >
                            {currentItems.map((item: IHorizonTalCalendarData, rowIndex)=>{
                                return (
                                    <tr>
                                        <td style={{width: '5%'}}></td>
                                        {
                                            dateList.map((date, index) => {
                                                if(renderCellBody){
                                                    let renderEstimateCell = () => {
                                                        if(renderEstimateCellBody){
                                                            if(estimateActivateDateState && estimateActivateDateState.length > 0){
                                                                let resultDateList = convertDateDataList(item.activateDate);
                                                                let tmpEstimateDate = convertDateData(estimateActivateDateState[0]);
                                                                let hasOverlap: boolean = DateFormat.hasOverlapDate(resultDateList, tmpEstimateDate);
                                                                // console.log("hasOverlap: ", {hasOverlap: hasOverlap, item: item});
                                                                let estimateCellStack: any = [];
                                                                let isFromEstimateActivateDates = isEqFromDate(estimateActivateDateState, date, dateList[0]);
                                                                isFromEstimateActivateDates.forEach((isFromEstimateActivateDate) => {
                                                                    let colspanVal = isFromEstimateActivateDate.diffDate + 1;
                                                                    colspanVal = index + colspanVal > dateList.length ? dateList.length - index : colspanVal;
                                                                    let width4Absolute = (colspanVal * 100) + ((colspanVal - 1) * 3);
                                                                    let fromShowDate = DateFormat.convert2ShowDateFormat(isFromEstimateActivateDate.fromDate);
                                                                    let toShowDate = DateFormat.convert2ShowDateFormat(isFromEstimateActivateDate.toDate);
                                                                    let showDate = `${fromShowDate} - ${toShowDate}`;
                                                                    let dataValue = isFromEstimateActivateDate.dataValue;
                                                                    estimateCellStack.push((
                                                                        <div style={{display: 'flex', position: 'absolute', height: '100%', width: `${width4Absolute}%`, zIndex: index}} title={showDate}>
                                                                            {renderEstimateCellBody(date, item, rowIndex, index, hasOverlap, dataValue)}
                                                                        </div>
                                                                    ));
                                                                });
                                                                return estimateCellStack;
                                                            }
                                                        }
                                                    }
                                                    let isFromActivateDates = isEqFromDate(item.activateDate, date, dateList[0]);
                                                    if(isFromActivateDates.length > 0){
                                                        let cellBodyStack: any = [];
                                                        isFromActivateDates.forEach((isFromActivateDate) => {
                                                            let colspanVal = isFromActivateDate.diffDate + 1;
                                                            colspanVal = index + colspanVal > dateList.length ? dateList.length - index : colspanVal;
                                                            let width4Absolute = (colspanVal * 100) + ((colspanVal - 1) * 3);
                                                            let fromShowDate = DateFormat.convert2ShowDateFormat(isFromActivateDate.fromDate);
                                                            let toShowDate = isFromActivateDate.toDate == null || isFromActivateDate.toDate.trim() == "" ? "ไม่ระบุ" : DateFormat.convert2ShowDateFormat(isFromActivateDate.toDate);
                                                            let showDate = `${fromShowDate} - ${toShowDate}`;
                                                            let dataValue = isFromActivateDate.dataValue;
                                                            cellBodyStack.push((
                                                                <div style={{display: 'flex', position: 'absolute', height: '100%', width: `${width4Absolute}%`, zIndex: index}} title={showDate}>
                                                                    {renderCellBody(date, item, rowIndex, index, dataValue)}
                                                                </div>
                                                            ));
                                                        });
                                                        return (
                                                            <td style={{height: '39px', ...styles.borderStyle(true)}}>
                                                                <div style={{display: 'flex', position: 'relative', height: '100%', width: '100%'}}>
                                                                    {cellBodyStack}
                                                                    {renderEstimateCell()}
                                                                </div>
                                                            </td>
                                                        );
                                                    }else{
                                                        return (
                                                            <td style={{height: '39px', ...styles.borderStyle(true), }}>
                                                                <div style={{display: 'flex', position: 'relative', height: '100%', width: '100%'}}>
                                                                    {renderEstimateCell()}
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                }else{
                                                    let isFromActivateDates = isEqFromDate(item.activateDate, date, dateList[0]);
                                                    if(isFromActivateDates.length > 0){
                                                        let isFromActivateDate = isFromActivateDates[0];
                                                        let colspanVal = isFromActivateDate.diffDate + 1;
                                                        colspanVal = index + colspanVal > dateList.length ? dateList.length - index : colspanVal;
                                                        return (
                                                            <td style={{height: '39px', ...styles.borderStyle(true), textAlign: 'center', backgroundColor: correctColorCode}} colSpan={colspanVal}>
                                                            </td>
                                                        );
                                                    }else if(!isBetweenActivateDate(item.activateDate, date)){
                                                        return (
                                                            <td style={{height: '39px', ...styles.borderStyle(true), textAlign: 'center'}}>
                                                            </td>
                                                        );
                                                    } 
                                                }
                                            })
                                        }
                                        <td style={{width: '5%'}}></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }
    }
    return (
        <div>
            <table
                ref={(el: HTMLTableElement) => {setMainContainer(el);}}
                // className={`d-flex ${className ? className : ""} flex-column 
                //     ${alignItems ? "align-items-" + alignItems : ""} ${
                //     justifyContent ? "justify-content-" + justifyContent : ""
                // }`}
                // onMouseUp={(evt: React.MouseEvent<HTMLDivElement>) => {setIsMouseDown(false)}}
                style={{ width: "100%", tableLayout: 'fixed', borderRadius: '10px'}}
            >
                <thead className={mStyles['mTHead']}>
                    <tr style={{width: '100%'}}>
                        <th 
                            rowSpan={2}
                            style={{width:'5%', textAlign: 'center', cursor: 'pointer'}}
                            onClick={onLeftClick}
                        >
                            <img src='/assets/left-arrow.png'
                                style={{width: '12px', height: '12px', alignItems: 'center'}}/>
                        </th>
                        {yearList.map((yearData, index) => {
                            return (
                                <th 
                                    style={{textAlign: 'center', backgroundColor: '#F8F8F8', borderBottom: '#989898 solid 1px', ...styles.borderStyle(true)}}
                                    colSpan={yearData.countDate}
                                >
                                    {yearData.yearLabel}
                                </th>
                            )
                        })}
                        <th 
                            rowSpan={2}
                            style={{width:'5%', textAlign: 'center', cursor: 'pointer'}}
                            onClick={onRightClick}
                        >
                            <img src='/assets/right-arrow.png'
                                style={{width: '12px', height: '12px', alignItems: 'center'}}/>
                        </th>
                    </tr>
                    <tr style={{width: '100%'}}>
                            {dateList.map((date, index) => {
                                return (
                                    <th style={{textAlign: 'center', height: '100%', backgroundColor: '#F8F8F8', ...styles.borderStyle(true)}}>
                                        {DateFormat.convert2ShowDateMonthFormat(DateFormat.convertDate2DBDateFormat(date))}
                                    </th>
                                )
                            })}
                    </tr>
                </thead>
            </table>
            {renderCalenderBody()}
        </div>
    );
};

const styles = {
    button: {
        width: '60px', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: '#F8F8F8', 
        padding: '5px 0px 5px 0px', 
        margin: '5px 0px 5px 0px', 
        borderRadius: 5, 
        cursor: "pointer", 
    },
    arrow: {
        width: '5px',
        height: '5px',
        border: 'solid #CCC',
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: '3px',
        cursor: "pointer", 
        margin: '10px 0 10px 0'
    },
    
    right: {
        transform: 'rotate(-45deg)',
        // -webkit-transform: rotate(-45deg),
    },
    
    left: {
        // transform: 'rotate(135deg)',
        backgroundImage: `url("/assets/left-arrow.png")`,
        // -webkit-transform: rotate(135deg),
    },
    
    up: {
        transform: 'rotate(-135deg)',
        // -webkit-transform: rotate(-135deg),
    },
    
    down: {
        transform: 'rotate(45deg)',
        // -webkit-transform: rotate(45deg),
    },

    contentBody: { 
        width: "90%", 
        padding: '0px', 
        margin: '0',
        overflow: 'hidden', 
    },
    dateHeader: {
        width: '90%', 
        alignItems: 'center', 
        justifyContent: 'center', 
        // backgroundColor: '#F8F8F8', 
        padding: '0px 0px 0px 0px', 
        margin: '0px 0px 0px 0px', 
        borderRadius: 5
    },
    borderStyle: (isShowRightBorder: boolean) => {
        return isShowRightBorder ? {
            // borderLeft: '#989898 solid 1px',
            // borderRight: '#989898 solid 1px'
        } : {
            // borderLeft: '#989898 solid 1px',
        };
    },
}
export default HorizontalCalendar;
