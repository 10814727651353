import React, { useEffect, useState } from "react";
import { Button, Steps } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import PageHeader from "src/layouts/PageHeader";
import { useHistory } from "react-router";
import StepListPayment from "./StepListPayment";
// import StepListOrder from "./StepListOrder";
// import StepTypePayment from "./StepTypePayment";
// import StepConfirmCash from "./StepConfirmCash";
// import StepListSMC from "./StepListSMC";
// import StepListStatement from "./StepListStatement";
// import StepConfirmPayIn from "./StepConfirmPayIn";
// import StepConfirmQR from "./StepConfirmQR";
// import { BillHead } from "src/domain/modelBillType";

const { Step } = Steps;

const FormPayment = () => {
    const history = useHistory();
    const [current, setCurrent] = useState<any>(0);
    const [paymentDoc, setPaymentDoc] = useState<string>("");
    const [paymentType, setPaymentType] = useState<string>("");
    //   const [billHeads, setBillHeads] = useState<BillHead[]>([]);

    console.log(paymentDoc, paymentType);

    //   useEffect(() => {
    //     setBillHeads([]);
    //     setPaymentType("");
    //   }, [paymentDoc]);

    const onChange = () => {
        setCurrent(current);
    };

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    //   const handleAddOrder = (data: [BillHead]) => {
    //     setBillHeads(data);
    //   };

    const paymentTypeContent = () => {
        if (paymentType === "cash") {
            return (
                <div>
                    {/* <StepConfirmCash
            onPrevStep={() => {
              prev();
            }}
            paymentDoc={paymentDoc}
            paymentType={paymentType}
            billHeads={billHeads}
          /> */}
                </div>
            );
        } else if (paymentType === "payIn") {
            return (
                <div>
                    {/* <StepConfirmPayIn /> */}
                </div>
            );
        } else if (paymentType === "Qr") {
            return (
                <div>
                    {/* <StepConfirmQR /> */}
                </div>
            );
        }
    };

    const paymentListContent = () => {
        if (paymentDoc === "order") {
            return (
                <div>
                    {/* <StepListOrder
            onNextStep={() => {
              next();
            }}
            onPrevStep={() => {
              prev();
            }}
            onAddBill={handleAddOrder}
            billHeads={billHeads}
          /> */}
                </div>
            );
        } else if (paymentDoc === "smc") {
            return (
                <div>
                    {/* <StepListSMC
            onNextStep={() => {
              next();
            }}
            onPrevStep={() => {
              prev();
            }}
          /> */}
                </div>
            );
        } else if (paymentDoc === "statement") {
            return (
                <div>
                    {/* <StepListStatement
            onNextStep={() => {
              next();
            }}
            onPrevStep={() => {
              prev();
            }}
          /> */}
                </div>
            );
        }
    };

    const steps = [
        {
            title: "รายการรับชำระ",
            content: (
                <StepListPayment
                    onButtonOrder={() => {
                        setPaymentDoc("order");
                        next();
                    }}
                    onButtonSMC={() => {
                        setPaymentDoc("smc");
                        next();
                    }}
                    onButtonStatement={() => {
                        setPaymentDoc("statement");
                        next();
                    }}
                    docType={paymentDoc}
                />
            ),
        },
        {
            title: "รายละเอียด",
            content: paymentListContent(),
        },
        {
            title: "วิธีการรับชำระ",
            //   content: (
            //     <StepTypePayment
            //       onButtonCash={() => {
            //         setPaymentType("cash");
            //         next();
            //       }}
            //       onButtonPayIn={() => {
            //         setPaymentType("payIn");
            //         next();
            //       }}
            //       onButtonQR={() => {
            //         setPaymentType("Qr");
            //         next();
            //       }}
            //       onPrevStep={() => {
            //         prev();
            //       }}
            //       payType={paymentType}
            //     />
            //   ),
        },
        {
            title: "ยืนยันรับชำระ",
            content: paymentTypeContent(),
        },
    ];
    return (
        <>
            {/* <PageHeader title="รับชำระ">
        <Button
          size="small"
          icon={<CloseCircleOutlined />}
          onClick={() => history.goBack()}
          shape="circle"
          type="primary"
          danger
        ></Button>
      </PageHeader> */}
            <Steps
                type="navigation"
                current={current}
                className="steps-content"
                onChange={onChange}
            >
                {steps.map((item) => (
                    <Step key={item.title} title={item.title}></Step>
                ))}
            </Steps>
            <div>{steps[current].content}</div>
        </>
    );
};

export default FormPayment;