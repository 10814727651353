export const menus = [
  // {
  //   moduleCode: "001",
  //   moduleMenus: [{ id: 1, name: "Overview", path: "/app/dashboard" }],
  // },
  {
    moduleCode: "002",
    moduleMenus: [
      { id: 2, name: "ใบสั่งปฎิบัติงาน", path: "/app/jobrenger/list" , roles:["BRANCH_USER", "BRANCH_MANAGER","SUPERVISOR", "ADMIN"]},
      // { id: 3, name: "ออกใบ Tracking", path: "/app/tracking", roles: ["SUPERVISOR", "ADMIN"] },
      // { id: 13, name: "BankTransfer", path: "/app/banktransfer", roles: ["SUPERVISOR", "ADMIN"] },
      // { id: 4, name: "Payment", path: "/app/payment", roles: ["BRANCH_USER", "BRANCH_MANAGER","SUPERVISOR", "ADMIN"] },
    ],
  },
  {
    moduleCode: "003",
    moduleMenus: [
      // { id: 5, name: "Counter", path: "/app/counter" }
      { id: 5, name: "รถบรรทุก", path: "/app/truck", roles: ["SUPERVISOR", "ADMIN"] },
      { id: 6, name: "คนขับรถ", path: "/app/driver", roles: ["SUPERVISOR", "ADMIN"]},
    ],
  },
  {
    moduleCode: "004",
    moduleMenus: [
      // { id: 6, name: "Teacher", path: "/app/teacher" },
      // { id: 7, name: "Student", path: "/app/student" },
      // { id: 8, name: "Subject", path: "/app/subject" },
      // { id: 9, name: "Officer", path: "/app/officers" },
      { id: 9, name: "Partner", path: "/app/partner", roles: ["SUPERVISOR", "ADMIN"]  },
      { id: 10, name: "Business", path: "/app/business" , roles: ["SUPERVISOR", "ADMIN"]},
    ],
  },
  // {
  //   moduleCode: "005",
  //   moduleMenus: [
  //     { id: 13, name: "ตัดของรอจำหน่าย", path: "/app/billTracking", roles: ["ADMIN"] },
  //     { id: 14, name: "ลูกค้ารับของ", path: "/app/custReceive", roles: ["ADMIN"] },
  //     { id: 16, name: "รายงานสินค้าเข้าสาขา", path: "/app/releaseSMC/report/productInStationReport", roles: ["ADMIN"] }
  //   ],

  // },
  {
    moduleCode: '999',
    moduleMenus: [{ id: 15, name: 'Change password', path: '/app/profile/changepassword', roles: ["BRANCH_USER", "BRANCH_MANAGER","SUPERVISOR", "ADMIN"]}]
  }
];