import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import AppLayout from "src/layouts/AppLayout";

import Dashboard from "./Dashboard";
import TruckList from "./truck/TruckList";
import DriverAdd from "./driver/DriverAdd";
import DriverList from "./driver/DriverList";

import FormPayment from "./payment/FormPayment";
import PartnerForm from "./partner/PartnerForm";
import PartnerList from "./partner/PartnerList";
import TruckForm from "./truck/TruckForm";


import TruckMatchingList from "./truckmatching/TruckMatchingList";
import TruckMatchingManage from "./truckmatching/TruckMatchingManage";
import TruckMatchingSendRequest from "./truckmatching/TruckMatchingSendRequest";

import JobListMain from "./sendjob2partner/JobListMain";
import SelectPartner from "./sendjob2partner/SelectPartner";
import ViewJobPartnerDetail from "./sendjob2partner/ViewJobPartnerDetail";
import BusinessForm from "./Business/BusinessForm";
import RegistBusinessList from "./Business/RegistBusinessList";
import RegistBusinessForm from "./Business/RegistBusinessForm";
import PartnerAvatar from "./partner/PartnerAvatar";
import AuthGuard from "src/components/AuthGuard";
import ChangePassword from "./secUser/ChangePassword";
import JobRengerMoveList from "./jobrenger/JobRengerMoveList";
import  SearchDatePopup  from "./jobrenger/SearchDatePopup";
import AddJobRengerMove from "./jobrenger/AddJobRengerMove";
import ModernAppLayout from "../layouts/ModernAppLayout";
import DriverForm from "./driver/DriverForm";


const AppRoute: React.FC<RouteComponentProps> = (props) => {
  // console.log(props);
  return (
    <AuthGuard>
      <ModernAppLayout>
        <Switch>
          {/* dashboard */}
          <Route exact path="/app/dashboard" component={Dashboard} />

          {/* matching management */}
          <Route exact path="/app/matching/list" component={TruckMatchingList} />
          <Route exact path="/app/matching/manage/:selectedDate/:truckTypeId" component={TruckMatchingManage} />
          <Route exact path="/app/matching/manage/sendRequest" component={TruckMatchingSendRequest} />

          {/* send job to partner */}
          <Route exact path="/app/send2partner/list" component={JobListMain} />
          <Route exact path="/app/send2partner/selectpartner" component={SelectPartner} />
          <Route exact path="/app/send2partner/view" component={ViewJobPartnerDetail} />
          <Redirect path="/app/send2partner" to="/app/send2partner/list" />


          {/* owner */}
          {/* <Route exact path="/app/owner/new" component={OwnerAdd} /> */}
          {/* <Route exact path="/app/owner/list" component={OwnerList} /> */}
          {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
          <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
          {/* <Redirect path="/app/owner" to="/app/owner/list" /> */}

          {/* owner */}
          <Route exact path="/app/partner/new" component={PartnerForm} />
          <Route exact path="/app/partner/list" component={PartnerList} />
          <Route exact path="/app/partner/edit/:partnerUId" component={PartnerForm} />
          <Route exact path="/app/partner/partnerAvatar" component={PartnerAvatar} />
          {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
          <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
          <Redirect path="/app/partner" to="/app/partner/list" />

          {/* truck */}
          <Route exact path="/app/truck/new" component={TruckForm} />
          <Route exact path="/app/truck/edit/:truckUId" component={TruckForm} />
          <Route exact path="/app/truck/list" component={TruckList} />
          {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
          <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
          <Redirect path="/app/truck" to="/app/truck/list" />

          {/* driver */}
          <Route exact path="/app/driver/new" component={DriverAdd} />
          <Route exact path="/app/driver/list" component={DriverList} />
          <Route exact path="/app/driver/edit/:driverUId" component={DriverForm} />
          {/* <Route exact path="/app/owner/edit/:id" component={EditOfficer} />
          <Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
          <Redirect path="/app/driver" to="/app/driver/list" />


          {/* business */}
          {/* <Route exact path="/app/business/new/:id" component={RegistBusinessForm} /> */}
          <Route exact path="/app/business/new/:id" component={BusinessForm} />
          <Route exact path="/app/business/list" component={RegistBusinessList} />
          <Route exact path="/app/business/edit/:id" component={RegistBusinessForm} />
          {/*<Route exact path="/app/owner/view/:id" component={ViewOfficer} /> */}
          <Route exact path="/app/business/payment" component={FormPayment} />
          <Redirect path="/app/business" to="/app/business/list" />


          {/* jobrenger */}
          <Route exact path="/app/jobrenger/list" component={JobRengerMoveList} />
          <Route exact path="/app/jobrenger/form/:jobType" component={AddJobRengerMove} />
          <Route exact path="/app/jobrenger/view/:jobNo" component={AddJobRengerMove} />
          <Redirect path="/app/jobrenger" to="/app/jobrenger/list" />


          {/* secuser */}
          <Route exact path="/app/secuser/changepassword" component={ChangePassword} />


          {/* other */}
          <Redirect path="/app" to="/app/dashboard" />
        </Switch>
      </ModernAppLayout>
    </AuthGuard>
  );
};

export default AppRoute;
